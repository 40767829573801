import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Image, Header, Loader } from 'semantic-ui-react';

import { userTypes } from '../../modules/roles';
import ProfileVerificationModal from '../../components/users/ProfileVerificationModal';
import CertifyTrainerModal from '../../components/users/CertifyTrainerModal';
import {
  setUserSuspended,
  decertifyTrainer,
  unverifyProfile,
} from '../../services/users';
import update from 'immutability-helper';
import SvgImage from '../SvgImage';
import { FLEXPIT_ICONS } from '../../assets/icons/flexpit';

const apiCalls = {
  suspend: 'suspend',
  unverify: 'unverify',
  decertify: 'decertify',
};

export default class UserHeader extends Component {
  static propTypes = {
    requestRefreshUser: PropTypes.func.isRequired,
    user: PropTypes.shape({
      approved: PropTypes.bool,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      photoUrl: PropTypes.string,
      documentation: PropTypes.array,
      username: PropTypes.string,
      userType: PropTypes.string,
      suspendedProfile: PropTypes.bool,
      verificationDocuments: PropTypes.array,
      verified: PropTypes.bool,
    }).isRequired,
    isLoading: PropTypes.bool,
  };

  state = {
    cancellingStuff: {
      isOpen: false,
      what: '',
      form: {
        reason: '',
      },
    },
    error: undefined,
    isVerifyProfileModalOpen: false,
    isCertifyModalOpen: false,
    waitingActionResponse: '',
  };

  /**
   * Generic function for simple API calls.
   *
   * @param { Object } arguments - arguments
   *   @param { Function } apiFunc - the API function call (must return a Promise)
   *   @param { String } action - a code for the action type (used in
   *    `waitingActionResponse`)
   *   @param { Object } params (optional) - the params to pass to the api call
   *   @param { Object } beforeCallStateUpdate (optional) - an update object to
   *    pass before calling (follows `immutability-helper` query format)
   */
  async requestActionCall({
    apiFunc,
    params,
    action,
    beforeCallStateUpdate = {},
  }) {
    const { requestRefreshUser } = this.props;

    if (!apiFunc) {
      console.error('requestActionCall: No API function provided');
    }

    try {
      this.setState(
        update(this.state, {
          ...beforeCallStateUpdate,
          waitingActionResponse: { $set: action },
        }),
      );
      await apiFunc(params);
      await requestRefreshUser();
      this.setState({ error: null, waitingActionResponse: '' });
    } catch (error) {
      console.error(error);
      this.setState(
        update(this.state, {
          error: { $set: error },
          waitingActionResponse: { $set: '' },
        }),
      );
    }
  }

  toggleSuspensionUser = () => {
    const { user: { uid, suspendedProfile } } = this.props;

    return this.requestActionCall({
      apiFunc: setUserSuspended,
      params: { uid, suspend: !suspendedProfile },
      action: apiCalls.suspend,
    });
  };

  // Verify profile modal open/close manage function
  openVerifyProfileModal = () =>
    this.setState({ isVerifyProfileModalOpen: true });
  closeVerifyProfileModal = () =>
    this.setState({ isVerifyProfileModalOpen: false });

  // Certification modal open/close manage function
  openCertificationModal = () => this.setState({ isCertifyModalOpen: true });
  closeCertificationModal = () => this.setState({ isCertifyModalOpen: false });

  // Cancel verification/certification functions
  openCancellingModal = ({ target: { value } }) => {
    this.setState(
      update(this.state, {
        cancellingStuff: {
          isOpen: { $set: true },
          what: { $set: value },
        },
      }),
    );
  };

  closeCancellingModal = () => {
    this.setState(
      update(this.state, {
        cancellingStuff: {
          form: {
            reason: { $set: '' },
          },
          isOpen: { $set: false },
        },
      }),
    );
  };

  /**
   * Handles form changes to the cancel modal
   */
  handleCancelFormChange = ({ target: { name, value } }) => {
    this.setState(
      update(this.state, {
        cancellingStuff: { form: { [name]: { $set: value } } },
      }),
    );
  };

  handleCancelModalMainAction = ({ target, target: { value } }) => {
    const { user: { uid } } = this.props;
    const { cancellingStuff: { form } } = this.state;

    let apiFunc;
    let extraParams = {};

    switch (value) {
      case apiCalls.decertify: {
        apiFunc = decertifyTrainer;
        break;
      }

      case apiCalls.unverify: {
        apiFunc = unverifyProfile;
        extraParams = { removeReferal: true };
        break;
      }

      default: {
        console.error('Malformed action type for cancel modal', {
          action: value,
        });
        return;
      }
    }

    const params = {
      uid,
      feedback: form.reason,
      ...extraParams,
    };

    this.requestActionCall({
      apiFunc,
      params,
      action: value,
      beforeCallStateUpdate: {
        cancellingStuff: {
          form: {
            reason: { $set: '' },
          },
          isOpen: { $set: false },
        },
      },
    });
  };

  renderButtons({ pendingCertification, pendingVerification }) {
    const {
      user: { approved, userType, suspendedProfile, verified },
    } = this.props;
    const { waitingActionResponse } = this.state;

    let certifyButton;

    // Is the user a Trainer?
    if (userType === userTypes.trainer) {
      if (pendingCertification) {
        // The user has a pending certification
        certifyButton = (
          <Button
            circular
            primary // Highlight if pending
            onClick={this.openCertificationModal}
          >
            Certify trainer
          </Button>
        );
      } else if (approved) {
        // The user is already certified, show button to uncertify them
        certifyButton = (
          <Button
            className="-tertiary"
            negative
            onClick={this.openCancellingModal}
            value={apiCalls.decertify}
            loading={waitingActionResponse === apiCalls.decertify}
            disabled={Boolean(waitingActionResponse)}
          >
            Decertify trainer
          </Button>
        );
      }
    }

    // Verify/Unverify button
    let verifyProfileButton;
    if (pendingVerification) {
      verifyProfileButton = (
        <Button
          circular
          primary // Highlight if pending
          basic
          onClick={this.openVerifyProfileModal}
        >
          Verify profile
        </Button>
      );
    } else if (verified) {
      verifyProfileButton = (
        <Button
          className="-tertiary"
          negative
          onClick={this.openCancellingModal}
          value={apiCalls.unverify}
          loading={waitingActionResponse === apiCalls.unverify}
          disabled={Boolean(waitingActionResponse)}
        >
          Unverify profile
        </Button>
      );
    }

    return (
      <div className="button-list">
        {certifyButton}
        {verifyProfileButton}
        <Button
          className="-tertiary"
          negative
          onClick={this.toggleSuspensionUser}
          loading={waitingActionResponse === apiCalls.suspend}
          disabled={Boolean(waitingActionResponse)}
        >
          {suspendedProfile ? 'Unsuspend' : 'Suspend'}
        </Button>
      </div>
    );
  }

  renderCancelModal() {
    const { user: { username } } = this.props;
    const { cancellingStuff: { isOpen, what, form: { reason } } } = this.state;

    let modalProps = {};

    const form = (
      <Form>
        <Form.Input
          autoFocus
          fluid
          label="Reason"
          name="reason"
          placeholder={`Explain why you are ${what}ing @${username}...`}
          onChange={this.handleCancelFormChange}
          value={reason}
        />
      </Form>
    );

    const actions = [
      {
        key: 'cancel-action',
        basic: true,
        circular: true,
        content: 'Cancel',
        onClick: this.closeCancellingModal,
      },
      {
        key: 'main-action',
        circular: true,
        negative: true,
        onClick: this.handleCancelModalMainAction,
        value: what,
      },
    ];

    const mainActionButton = actions[1];

    switch (what) {
      case apiCalls.decertify: {
        // Change main action button props
        mainActionButton.content = 'Decertify';

        modalProps = {
          header: 'Decertify trainer?',
          content: (
            <Modal.Content>
              <p className="information">
                <strong className="strong">@{username}</strong> won't be able to
                verify lifts anymore and will receive a notification with the
                reason for being decertified. To be certified again, they will
                need to send a new request.
              </p>
              {form}
            </Modal.Content>
          ),
        };
        break;
      }

      case apiCalls.unverify: {
        // Change main action button props
        mainActionButton.content = 'Unverify';

        modalProps = {
          header: 'Unverify profile?',
          content: (
            <Modal.Content>
              <p className="information">
                <strong className="strong">@{username}</strong> won't be
                considered as an officially verified profile anymore and will
                receive a notification with the reason for being unverified. To
                be verified again, they will need to send a new request.
              </p>
              {form}
            </Modal.Content>
          ),
        };
        break;
      }

      default: {
        console.error('Malformed state when rendering cancel modal', {
          what,
        });
        break;
      }
    }

    let header;
    let content;
    ({ header, content, ...modalProps } = modalProps);

    return (
      <Modal
        {...modalProps}
        open={isOpen}
        onClose={this.closeCancellingModal}
        size="tiny"
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        <Modal.Actions>
          {actions.map(props => <Button {...props} />)}
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const {
      isLoading,
      user,
      user: {
        firstName,
        lastName,
        photoUrl,
        documentation,
        reference,
        username,
        userType,
        verificationDocuments,
        verified,
      },
      requestRefreshUser,
    } = this.props;

    const {
      isCertifyModalOpen,
      isVerifyProfileModalOpen,
      cancellingStuff,
    } = this.state;

    const pendingCertification = documentation && documentation.length > 0;

    const pendingVerification =
      !verified &&
      ((verificationDocuments && verificationDocuments.length > 0) ||
        (userType === 'Scout' && reference)); // Scout verification

    return (
      <Fragment>
        <Header as="header" size="huge">
          <Loader active={isLoading} inline size="large" />
          {!isLoading && photoUrl && <Image circular src={photoUrl} />}
          {!isLoading && (
            <Header.Content>
              <Header.Subheader>
                {firstName} {lastName}{' '}
                {verified && (
                  <SvgImage
                    icon
                    aria-label="Verified profile"
                    src={FLEXPIT_ICONS.verifiedProfile.svg}
                    fallback={FLEXPIT_ICONS.verifiedProfile.png}
                    className="verifiedicon"
                    size="tiny"
                  />
                )}
              </Header.Subheader>
              @{username}
              {this.renderButtons({
                pendingCertification,
                pendingVerification,
              })}
            </Header.Content>
          )}
        </Header>
        {!isLoading &&
          pendingCertification && (
            <CertifyTrainerModal
              key="certify-trainer-modal"
              size="small"
              open={isCertifyModalOpen}
              close={this.closeCertificationModal}
              documentation={documentation}
              userUid={user.uid}
              onFinishCertification={requestRefreshUser}
            />
          )}
        {!isLoading &&
          pendingVerification && (
            <ProfileVerificationModal
              key="verify-profile-modal"
              open={isVerifyProfileModalOpen}
              size="small"
              close={this.closeVerifyProfileModal}
              verificationDoc={verificationDocuments}
              reference={reference}
              userType={userType}
              userUid={user.uid}
              onFinishVerification={requestRefreshUser}
            />
          )}
        {cancellingStuff.what !== '' && this.renderCancelModal()}
      </Fragment>
    );
  }
}
