import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { firebaseAuth } from './../modules/firebase';
import { getApiEndpoint } from '../modules/api';
import { logError, ERROR_MESSAGES } from '../modules/errors';

/**
 * Authentication is a three-step process.
 * First, log in Firebase using an email and password, as the users are stored
 * in Firebase.
 * Second, using that token, log in on the API. The API end point returns a token.
 * Third, log in Firebase using that new custom token.
 */

/**
 * Logs in Firebase using the email and password (first step)
 * @param { String } email - the email of the user to log in
 * @param { String } password - the password of the user to log in
 * @returns { Promise }
 */
export const loginWithEmail = function loginWithEmail(email, password) {
  return firebaseAuth().signInWithEmailAndPassword(email, password);
};

/**
 * Logs in the API using the current token, and then logs in Firebase using
 * the custom token generated by the API.
 */
export const loginToApiAndFirebase = async function loginToApiAndFirebase() {
  try {
    const idToken = await firebaseAuth().currentUser.getIdToken();

    // If successful API returns data: { token: 'CUSTOM_JWT_HERE' }
    const { data } = await axios.get(getApiEndpoint.authentication.login(), {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    // Signout of firebase before signing in again with the new token
    await firebaseAuth().signOut();
    const { token } = data;
    await firebaseAuth().signInWithCustomToken(token);

    // Check if authorised
    const isAdmin = await checkIsAdmin();
    if (!isAdmin) {
      logout();
      throw new Error(ERROR_MESSAGES.notAnAdmin);
    }
  } catch (error) {
    let loggedError = error;

    if (error.response) {
      const { response: { status } } = error;

      // Not authorised, log out
      if (status === 403) {
        loggedError = new Error(ERROR_MESSAGES.notAnAdmin);
        logout();
      }
    }
    logError(loggedError);
  }
};

/**
 * Using the JWT, checks if the logged-in user is an admin.
 * @returns { Promise<boolean> } true if the user is an admin, false either.
 */
export const checkIsAdmin = async function checkIsAdmin() {
  let isAdmin = false;

  try {
    // Get JWT token
    const idToken = await firebaseAuth().currentUser.getIdToken();
    if (!idToken) {
      throw new Error('No id token found');
    }

    // Decode data from it
    const data = jwtDecode(idToken);
    if (!data) {
      throw new Error('Could not decode id token');
    }
    ({ isAdmin } = data);
  } catch (error) {
    throw new Error('Could not verify authorisation');
  }

  return isAdmin;
};

export const logout = () => {
  // Clear user token and profile data from localStorage
  return firebaseAuth().signOut();
};

export const getProfile = () => {
  return firebaseAuth().currentUser;
};

/**
 * Gets the currently logged-in user's uid.
 * @returns { string? } undefined if no user is logged in.
 */
export const getLoggedInUid = function getLoggedInUid() {
  return firebaseAuth().currentUser
    ? firebaseAuth().currentUser.uid
    : undefined;
};
