/**
 * Module containing any util function related to filtering or sorting iterable
 * objects.
 */

/**
 * Checks if the piece of data matches all the filters.
 * @param { Object } data The data to check
 * @param { Object } filters The list of filters to use. The key must be the
 * name of the field from data, and the value can be a Set if you want to match any
 * value contained in it, or any other type (in which case a simple === comparaison
 * is done).
 * @returns { boolean } true if the piece of data matches all the filters, false
 * either.
 */
export const matchFilters = function matchFilters(data, filters) {
  return Object.keys(filters).every(field => {
    // Get the filter and data value
    const filter = filters[field];
    const dataValue = data[field];

    // Check if the filter is empty
    const isFilterEmpty =
      filter === undefined ||
      filter === '' ||
      filter.length === 0 ||
      filter.size === 0;

    const result =
      filter instanceof Set ? filter.has(dataValue) : filter === dataValue;

    return isFilterEmpty || result;
  });
};

/**
 * Returns a subarray representing the data from the specified page.
 * @param { object } params
 * @param { Array } params.data The full data array.
 * @param { number } params.page The current page of data to get.
 * @param { number } [params.limit=20]  The number of pieces of data by page.
 *
 * @returns { Array } A new array containg #limit or less items.
 */
export const paginateData = function paginateData({ page, data, limit = 20 }) {
  const startIndex = (page - 1) * limit;
  const endIndex = page * limit;

  // When the table is shorter than the number by page, just get the table
  const slicedData =
    data.length > limit ? data.slice(startIndex, endIndex) : data;

  return slicedData;
};
