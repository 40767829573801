import { db } from '../modules/firebase';

/*
 * Overview Stats
 */
export const getOverviewStats = async () => {
  try {
    const [
      totalUsers,
      totalTrainers,
      totalAthletes,
      totalPosts,
    ] = await Promise.all([
      getTotalUsers(),
      getTotalTrainers(),
      getTotalAthletes(),
      getTotalPosts(),
    ]);

    return {
      totalUsers: totalUsers,
      totalTrainers: totalTrainers,
      totalAthletes: totalAthletes,
      totalPosts: totalPosts,
    };
  } catch (e) {
    console.log('Error getOverviewStats => ' + e);
  }
};

/*
 * Total Users
 */
export const getTotalUsers = async () => {
  try {
    const totalUsers = await db
      .collection('users')
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.size;
      });
    return totalUsers;
  } catch (e) {
    console.log('Error getTotalUsers => ' + e);
  }
};

/*
 * Total Posts
 */
export const getTotalPosts = async () => {
  try {
    const totalPosts = await db
      .collection('posts')
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.size;
      });
    return totalPosts;
  } catch (e) {
    console.log('Error getTotalPosts => ' + e);
  }
};

/*
 * Total Trainers
 */
export const getTotalTrainers = async () => {
  try {
    const totalTrainers = await db
      .collection('users')
      .where('userType', '==', 'Trainer')
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.size;
      });
    return totalTrainers;
  } catch (e) {
    console.log('Error getTotalTrainers => ' + e);
  }
};

/*
 * Total Athletes
 */
export const getTotalAthletes = async () => {
  try {
    const totalAthletes = await db
      .collection('users')
      .where('userType', '==', 'Athlete')
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.size;
      });
    return totalAthletes;
  } catch (e) {
    console.log('Error getTotalAthletes => ' + e);
  }
};
