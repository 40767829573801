import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import classNames from 'classnames';

/**
 * Checks whether the piece of data should be filtered or not.
 * @param { Object } pieceOfData - the piece of data to check whether it is filtered or not
 * @param { string[] } fields - the list of fields to extract from the object to compare
 * @param { RegExp } regex - the regex given to the object
 * @param { string } value - the current filtering value
 * @returns { boolean } true if the piece of data matches the criteria, false else.
 */
const defaultFilterFunction = function defaultFilterFunction(
  pieceOfData,
  fields = [],
  regex,
  value,
) {
  const criterias = fields.map(field => pieceOfData[field]).join(' ');
  return !value || Boolean(criterias.match(regex));
};

/**
 * Generates a default regex.
 * @param { string } value - the filtering value
 */
const defaultRegex = function defaultRegex(value = '') {
  return new RegExp(`.*(${value}).*`, 'i');
};

class FilterInput extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string.isRequired,
      }),
    ).isRequired,

    /**
     * Function called when the filtering is done. Passed the list of data, or only
     * their id depending on #returnOnlyIds.
     */

    onFilter: PropTypes.func.isRequired,
    /**
     * Function used to filter the data. Passed one piece of data, and should
     * return `true` if the piece of data should be part of the results or `false`
     * if not.
     * @param { Object } data
     * @param { RegExp } regex
     * @param { string } value
     * @returns { boolean }
     */
    checkFilter: PropTypes.func,

    /**
     * You can use a basic filtering feature by just providing a list of fields
     * to extract from the data object to be used as a comparaison.
     */
    fields: PropTypes.arrayOf(PropTypes.string),
    regex: PropTypes.func,
    icon: PropTypes.string,
    onChange: PropTypes.func,
    returnOnlyIds: PropTypes.bool,
  };

  static defaultProps = {
    checkFilter: defaultFilterFunction,
    fields: [],
    regex: defaultRegex,
    icon: 'search',
    returnOnlyIds: true,
  };

  state = {
    isFiltering: false,
    filter: '',
  };

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;

    // If getting new data, refilter
    if (data !== nextProps.data) {
      this.filterData({ props: nextProps });
    }
  }

  filterData({ props = this.props, value = this.state.value }) {
    const { checkFilter, data, fields, onFilter, regex, returnOnlyIds } = props;
    const isFiltering = Boolean(value);

    if (isFiltering) {
      this.setState({ isFiltering: true });
    }

    const regexp = regex(value);

    const filteredData = isFiltering
      ? data.filter(piece => checkFilter(piece, fields, regexp, value))
      : data;

    onFilter(
      // Get only ids
      returnOnlyIds ? filteredData.map(piece => piece.uid) : filteredData,
    );

    this.setState({ isFiltering: false });
  }

  handleFilter = evt => {
    const { onChange } = this.props;

    const { target: { value } } = evt;

    this.setState({ filter: value });

    // Redistribute call up
    if (onChange) onChange(evt);

    this.filterData({ value });
  };

  render() {
    const {
      className,
      onChange,
      onFilter,
      checkFilter,
      loading,
      returnOnlyIds,
      regex,
      fields,
      ...props
    } = this.props;

    const { isFiltering, filter } = this.state;

    return (
      <Input
        {...props}
        className={classNames('-rounded', className)}
        loading={isFiltering || loading}
        onChange={this.handleFilter}
        value={filter}
      />
    );
  }
}

export default FilterInput;
