import React from 'react';
import classNames from 'classnames';

const Slicon = function Slicon({ className, name, size, ...props }) {
  return (
    <span
      aria-hidden
      className={classNames('sl-icon', `icon-${name}`, `-${size}`, className)}
      {...props}
    />
  );
};

export default Slicon;
