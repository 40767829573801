import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Loader, Modal, Table, Pagination } from 'semantic-ui-react';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { injectParamsIntoRoute, routes } from '../../modules/routes';
import { setUserAdmin, verifyProfile } from '../../services/users';
import { getLoggedInUid } from '../../services/authentication';
import TableCellLink from '../TableCellLink';
import { paginateData } from '../../modules/filtering';
import PaginationDropdown from '../PaginationDropdown';
import update from '../../../node_modules/immutability-helper';
import Slicon from '../icons/Slicon';
import SvgImage from '../SvgImage';
import { FLEXPIT_ICONS } from '../../assets/icons/flexpit';

const confirmingModalData = {
  toggleIsAdmin({ isAdmin, username }) {
    return {
      header: `${isAdmin ? 'Unmark' : 'Mark'} as admin?`,
      content: (
        <Modal.Content>
          Are you sure you want to {isAdmin ? 'unmark' : 'mark'}{' '}
          <strong>@{username}</strong> as an admin?
        </Modal.Content>
      ),
      mainAction: isAdmin ? 'Unmark' : 'Mark',
    };
  },
  verify({ isAdmin, username }) {
    return {
      header: 'Verify profile?',
      content: (
        <Modal.Content>
          Are you sure you want to verify <strong>@{username}</strong>'s
          profile?
        </Modal.Content>
      ),
      mainAction: 'Verify profile',
    };
  },
};

class UsersTable extends Component {
  static propTypes = {
    users: PropTypes.array,
    column: PropTypes.object,
    direction: PropTypes.object,
    requestRefreshData: PropTypes.func,
  };

  static defaultProps = {
    users: [],
  };

  state = {
    currentPage: 1,
    nbUsersByPage: 10,
    confirmingModal: {
      isOpen: false,
      action: '',
      userUid: '',
    },
    column: null,
    direction: null,
    users: [],
    waitingApiResponse: false,
  };

  selectActionTargetUser = createSelector(
    state => state.confirmingModal.userUid,
    (_, props) => props.users,
    (uid, users) => {
      const user = uid && users.find(user => user.uid === uid);
      return user;
    },
  );

  selectConfirmingModalProps = createSelector(
    state => state.confirmingModal,
    this.selectActionTargetUser,
    ({ isOpen, action }, user) => {
      let extraProps;
      if (action && user) {
        const propMaker = confirmingModalData[action];
        extraProps = propMaker(user);
      }

      return {
        open: isOpen,
        ...extraProps,
      };
    },
  );

  componentWillReceiveProps(nextProps) {
    const { currentPage, nbUsersByPage, users } = this.state;

    if (users !== nextProps.users) {
      let newState = { users: nextProps.users };

      const totalPages = Math.ceil(nextProps.users.length / nbUsersByPage);
      if (totalPages < currentPage) {
        newState = { ...newState, currentPage: totalPages };
      }
      this.setState(newState);
    }
  }

  async requestActionApiCall(func, params) {
    if (!func) return;

    const { requestRefreshData } = this.props;

    this.setState(
      update(this.state, {
        confirmingModal: {
          isOpen: { $set: false },
        },
        waitingApiResponse: { $set: params.uid || true },
      }),
    );

    try {
      await func(params);
      if (requestRefreshData) {
        await requestRefreshData();
      }
    } catch (error) {
      console.error(error);
    }
    this.setState({ waitingApiResponse: false });
  }

  handleSort = clickedColumn => () => {
    const { column, users, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        users: _.sortBy(users, user => {
          const value = user[clickedColumn];
          if (_.isString(value)) {
            return value.toLowerCase();
          }

          return value;
        }),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      users: users.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  handleChangeNumberItemsPagination = (e, { value }) =>
    this.setState({ nbUsersByPage: value });

  openConfirmingModal = () => this.setState({ isConfirmingModalOpen: true });
  closeConfirmingModal = () => {
    this.setState(
      update(this.state, {
        confirmingModal: {
          isOpen: { $set: false },
        },
      }),
    );
  };

  getMenuActions(user) {
    const { uid, isAdmin, verified } = user;

    return [
      verified
        ? undefined
        : {
            key: 'verify',
            text: 'Verify profile',
            icon: 'check',
            value: `verify-${uid}`,
          },
      // Do not display this option for the currently logged in user
      getLoggedInUid() === uid
        ? undefined
        : {
            key: 'toggleIsAdmin',
            text: `${isAdmin ? 'Unmark' : 'Mark'} as admin`,
            icon: 'legal',
            value: `toggleIsAdmin-${uid}`,
          },
    ].filter(option => !!option);
  }

  handleSelectAction = ({ target }, data) => {
    const { value } = data || target;
    if (value) {
      const [action, userUid] = value.split('-');
      this.setState(
        update(this.state, {
          confirmingModal: {
            isOpen: { $set: true },
            action: { $set: action },
            userUid: { $set: userUid },
          },
        }),
      );
    }
  };

  handleConfirmAction = () => {
    const { confirmingModal: { action, userUid } } = this.state;

    let apiFunc;
    let params;

    switch (action) {
      case 'toggleIsAdmin': {
        const user = this.selectActionTargetUser(this.state, this.props);
        if (!user) {
          console.warn(
            `<UsersTable />: could not determine user targetted by action with id '${userUid}'`,
          );
          return;
        }

        const { isAdmin, uid } = user;
        apiFunc = setUserAdmin;
        params = { uid, isAdmin: !isAdmin };
        break;
      }

      case 'verify': {
        apiFunc = verifyProfile;
        params = { uid: userUid };
        break;
      }

      default: {
        console.warn(`<UsersTable />: unknown action '${action}'`);
        break;
      }
    }

    if (apiFunc) {
      this.requestActionApiCall(apiFunc, params);
    }
  };

  handlePaginationChange = (e, { activePage }) =>
    this.setState({ currentPage: activePage });

  renderBody() {
    const { currentPage, nbUsersByPage, users } = this.state;

    const slicedUsers = paginateData({
      data: users,
      page: currentPage,
      limit: nbUsersByPage,
    });

    return slicedUsers.map(user => {
      const {
        uid,
        username,
        firstName,
        lastName,
        sport,
        verified,
        sportLevel,
        userType,
      } = user;

      const urlToDetails = injectParamsIntoRoute(routes.users.details, {
        userId: uid,
      });

      return (
        <Table.Row key={uid}>
          <TableCellLink to={urlToDetails}>{username}</TableCellLink>
          <TableCellLink to={urlToDetails}>{firstName}</TableCellLink>
          <TableCellLink to={urlToDetails}>{lastName}</TableCellLink>
          <TableCellLink to={urlToDetails}>{sport}</TableCellLink>
          <TableCellLink to={urlToDetails} textAlign="center">
            {this.renderVerified(verified)}
          </TableCellLink>
          <TableCellLink to={urlToDetails}>{sportLevel}</TableCellLink>
          <TableCellLink to={urlToDetails}>{userType}</TableCellLink>
          <Table.Cell textAlign="center">{this.renderActions(user)}</Table.Cell>
        </Table.Row>
      );
    });
  }

  renderActions(user) {
    const { waitingApiResponse } = this.state;

    const optionNodes = this.getMenuActions(user).map(props => (
      <Dropdown.Item
        key={props.key}
        {...props}
        onClick={this.handleSelectAction}
      />
    ));

    if (optionNodes.length === 0) {
      return undefined;
    }

    const isLoading =
      waitingApiResponse instanceof Boolean
        ? waitingApiResponse
        : waitingApiResponse === user.uid;
    const trigger = isLoading ? (
      <Loader size="tiny" active inline />
    ) : (
      <Slicon name="options-vertical" className="icon" />
    );

    return (
      <Dropdown
        className="-fromright"
        direction="right"
        icon={null}
        selectOnBlur={false}
        trigger={trigger}
        disabled={Boolean(waitingApiResponse)}
      >
        <Dropdown.Menu>{optionNodes}</Dropdown.Menu>
      </Dropdown>
    );
  }

  renderVerified(status) {
    return (
      status && (
        <SvgImage
          icon
          aria-label="Verified profile"
          src={FLEXPIT_ICONS.verifiedProfile.svg}
          fallback={FLEXPIT_ICONS.verifiedProfile.png}
          className="verifiedicon"
          size="tiny"
        />
      )
    );
  }

  render() {
    const { currentPage, nbUsersByPage, users, column, direction } = this.state;

    const totalPages = Math.ceil(users.length / nbUsersByPage);

    const {
      mainAction,
      ...confirmingModalProps
    } = this.selectConfirmingModalProps(this.state, this.props);

    return (
      <div className="table-container">
        <Table textAlign="left" selectable sortable className="-borderless">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'username' ? direction : null}
                onClick={this.handleSort('username')}
              >
                Username
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'firstName' ? direction : null}
                onClick={this.handleSort('firstName')}
              >
                First Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'lastName' ? direction : null}
                onClick={this.handleSort('lastName')}
              >
                Last Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'sport' ? direction : null}
                onClick={this.handleSort('sport')}
              >
                Sport
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'verified' ? direction : null}
                onClick={this.handleSort('verified')}
                textAlign="center"
              >
                Verified
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'level' ? direction : null}
                onClick={this.handleSort('level')}
              >
                Level
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'type' ? direction : null}
                onClick={this.handleSort('type')}
              >
                Type
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Slicon name="options-vertical" className="icon" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{this.renderBody()}</Table.Body>
        </Table>

        <Pagination
          firstItem={null}
          lastItem={null}
          className="pagelist"
          activePage={currentPage}
          onPageChange={this.handlePaginationChange}
          totalPages={totalPages}
        />

        <PaginationDropdown
          className="nbrowpicker"
          selection
          onChange={this.handleChangeNumberItemsPagination}
          value={nbUsersByPage}
        />

        <Modal
          size="tiny"
          actions={[
            {
              key: 'cancel',
              content: 'Cancel',
              basic: true,
              onClick: this.closeConfirmingModal,
            },
            {
              key: 'confirm',
              content: mainAction || 'Confirm',
              positive: true,
              onClick: this.handleConfirmAction,
            },
          ]}
          {...confirmingModalProps}
        />
      </div>
    );
  }
}

export default UsersTable;
