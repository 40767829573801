/**
 * The `humanise.js` module contains all functions related to humanising
 * or stringifying data.
 */

/**
 * Converts a number in kilos.
 * @param { number } number The number to convert.
 * @param { number } [precision = 0] The number of decimals of the resulting number.
 * @returns { string } A string representing the number converted in kilos, followed by 'k'.
 *
 * Examples:
 * kifyNumber(38487, 1) => `38.4k`.
 * kifyNumber(38487, 3) => `38.487k`.
 */
export const kifyNumber = function kifyNumber(number, precision = 0) {
  if (isNaN(number) || isNaN(precision)) {
    return undefined;
  }

  const numberKs = number / 1000;
  const factor = Math.pow(10, precision);
  const truncatedNumberKs = Math.trunc(numberKs * factor) / factor;

  return `${truncatedNumberKs}k`;
};

/**
 * Pluralises a word if necessary, depending on the number of items.
 * @param { string } noun The noun to pluralise.
 * @param { number } [nb = 2] The number of items.
 * @returns { string } If nb > 1, the noun finishing with an 's', else
 * just the noun.
 */
export const pluralise = function pluralise(noun, nb = 2) {
  if (nb > 1) {
    let pluralNoun;
    const lastLetters = noun.slice(-2);

    if (lastLetters.match(/.s|.x|.z|ch|sh/)) {
      pluralNoun = `${noun}es`;
    } else if (lastLetters[1] === 'y') {
      pluralNoun = `${noun.slice(0, -1)}ies`;
    } else {
      pluralNoun = `${noun}s`;
    }

    return pluralNoun;
  }

  return noun;
};

const punctuationRegex = /[!?.:;…,“”()]/;

/**
 * Add a mark of punctuation at the end of the string. If it is already there,
 * just returns the text.
 * @param { string } text The text to punctuate.
 * @param { string } [ mark = '.' ] The mark of punctuation to use.
 * @returns { string } The text with the specified mark of punctuation at the end.
 */
export const punctuate = function punctuate(text, mark = '.') {
  const lastChar = text[text.length - 1];

  if (lastChar !== mark) {
    return lastChar.match(punctuationRegex)
      ? `${text.slice(0, -1)}${mark}`
      : `${text}${mark}`;
  }
  return text;
};

/**
 * Creates a React-renderable list slicing the given text and replacing bits by nodes.
 * @param { string } child The string to replace nodes in.
 * @param { object[] } matches An array of data used for replacing text by nodes.
 * @param { number } matches[].index The index in the text where to start replacing by the node.
 * @param { string } matches[].matchedText The text matched by the RegExp.
 * @param { function } makeNode The function used to make a node. It is passed
 * the corresponding `matches[]`.
 * @returns { Array|String } An array of strings and React nodes that can be rendered
 * if matches were found, else just the string.
 */
export const replaceTextWithNodes = function replaceTextWithNodes(
  child,
  matches,
  makeNode,
) {
  // Split child into substrings to replace matches with nodes
  let startIndex = 0;
  const transformedChild = matches.reduce((acc, match) => {
    const { matchedText, index } = match;
    const stringBefore = child.slice(startIndex, index);

    const node = makeNode(match);

    // Start next string just after the node
    startIndex = index + matchedText.length;
    return acc.concat([stringBefore, node]);
  }, []);

  // Add the substring that's left if reducing did not reach the end of the child
  if (startIndex <= child.length) {
    const endString = child.slice(startIndex);
    transformedChild.push(endString);
  }

  // If `matches` was empty, just return the string.
  return transformedChild.length > 1 ? transformedChild : transformedChild[0];
};

/**
 * Creates a string from the given text with properly replaced mentions.
 * @param { string } child The string to be parsed.
 * @returns { Array|String } String with mentions parsed.
 */
export const parseText = function parseText(child) {
  const userMentionRegex = /\[(@[^:]+):([^\]]+)\]/g;
  const mentions = [];
  let match;

  while ((match = userMentionRegex.exec(child))) {
    if (match) {
      const [matchedText, username, uid] = match;
      const { index } = match;

      const data = {
        matchedText,
        uid,
        username,
        index,
      };
      mentions.push(data);
    }
  }

  const childWithUserMentions = replaceTextWithNodes(
    child,
    mentions,
    ({ username, uid }) => {
      return username;
    },
  );

  let newText;
  if (Array.isArray(childWithUserMentions)) {
    newText = childWithUserMentions.map(child => child).join('');
  } else {
    newText = childWithUserMentions;
  }

  return newText;
};
