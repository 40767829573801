import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Message, Segment, Header } from 'semantic-ui-react';

import LoginForm from '../components/LoginForm';
import {
  loginWithEmail,
  loginToApiAndFirebase,
} from './../services/authentication';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      isLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    const { noticeLoggingType } = this.props;

    this.setState({ isLoading: true });
    e.preventDefault();

    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    try {
      await noticeLoggingType('email');
      await loginWithEmail(email, password);
      await noticeLoggingType('custom-token');
      await loginToApiAndFirebase();
    } catch (error) {
      this.setState({ error, isLoading: false });

      document.getElementById('password').value = '';
    }
  }

  render() {
    const { isLoading, error: stateError } = this.state;
    const { error = stateError } = this.props;

    return (
      <div className="login-page">
        <Grid
          centered
          container
          doubling
          textAlign="center"
          verticalAlign="middle"
        >
          <Grid.Column>
            <Header as="h1" textAlign="center">
              FLEXPIT
            </Header>
            {error && (
              <Message negative>
                <Message.Header>
                  Login Failed {error.code && `(${error.code})`}
                </Message.Header>
                <p>{error.message}</p>
              </Message>
            )}

            <Segment className="-borderless" padded="extremely">
              <LoginForm
                handleSubmit={this.handleSubmit}
                loading={isLoading}
                disabled={isLoading}
              />
              <Segment basic textAlign="center">
                <a href="/">Forgot password?</a>
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Login);
