import React from 'react';
import ReactDOM from 'react-dom';

// Simple line icon library
import 'simple-line-icons/css/simple-line-icons.css';

import Auth from './Auth';
import registerServiceWorker from './registerServiceWorker';
import { initApiClient } from './modules/api';

import './styles/semantic/dist/semantic.min.css';

const render = function render(Component) {
  ReactDOM.render(<Component />, document.getElementById('root'));
};

initApiClient();
registerServiceWorker();

render(Auth);
