import axios from 'axios';
import check from 'check-types';
import { getApiEndpoint, getTokenedConfig } from '../modules/api';
import { db } from '../modules/firebase';
import { parseData } from './utils';

/**
 * Removes a post.
 * @param { string } uid The uid of the post to remove.
 * @returns { Promise } A Promise that resolves when the post is removed.
 */
export const removePost = function removePost(uid) {
  if (!check.nonEmptyString(uid)) {
    const error = new Error('Internal error: malformed arguments');
    error.params = { uid };
    console.error(error);
    throw error;
  }

  return db
    .collection('posts')
    .doc(uid)
    .delete()
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const getComments = async post => {
  try {
    const comments = await db
      .collection('posts')
      .doc(post)
      .collection('comments')
      .get()
      .then(querySnapshot => {
        const data = [];
        querySnapshot.forEach(doc => {
          data.push(parseData(doc.data()));
        });
        return data;
      });

    return comments;
  } catch (err) {
    console.log(err);
  }
};

const getPostWithUser = async function getPostWithUser(post) {
  const { userUid } = post;
  const user = await db
    .collection('users')
    .doc(userUid)
    .get()
    .then(doc => {
      if (!doc.exists) {
        throw new Error('Could not find user referenced by post');
      }

      return doc.data();
    });

  const newPost = {
    ...post,
    user,
  };

  return newPost;
};

export const getUnverifiedLifts = async function getUnverifiedLifts() {
  try {
    const liftsRef = db
      .collection('posts')
      .where('isLift', '==', true)
      .where('status', '==', 'unverified');

    // Get the data from lifts
    const basicLifts = await liftsRef.get().then(querySnapshot => {
      const data = [];
      querySnapshot.forEach(doc => data.push(parseData(doc.data())));
      return data;
    });

    // Get the user for each lift
    const lifts = await Promise.all(basicLifts.map(getPostWithUser));

    return lifts;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Subscribes to unverified lifts and their changes.
 * @param { Function } useData - Function to call when there are document changes.
 * It is passed an object with this structure:
 *   {
 *     lifts: {
 *       added: [],
 *       modified: [],
 *       removed: [],
 *     }
 *   }
 * @returns { Function } the function to unsuscribe. Call it when you do not need
 * the subscription anymore.
 */
export const subscribeToUnverifiedLifts = function subscribeToUnverifiedLifts(
  useData,
) {
  const liftsRef = db
    .collection('posts')
    .where('isLift', '==', true)
    .where('status', '==', 'unverified');

  const unsuscribe = liftsRef.onSnapshot(querySnapshot => {
    // Generate an object with the objects sorted by type of actions
    const lifts = querySnapshot.docChanges().reduce(
      (sortedData, change) => {
        const lift = change.doc.data();
        const parsedLift = parseData(lift);
        sortedData[change.type].push(parsedLift);
        return sortedData;
      },
      {
        added: [],
        modified: [],
        removed: [],
      },
    );
    useData({ lifts });
  });

  return unsuscribe;
};

export const getAbuses = async () => {
  try {
    const basicAbuses = await db
      .collection('posts')
      .where('reported', '==', true)
      .get()
      .then(querySnapshot => {
        const data = [];
        querySnapshot.forEach(doc => {
          data.push(parseData(doc.data()));
        });
        return data;
      });

    const abuses = await Promise.all(basicAbuses.map(getPostWithUser));

    return abuses;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/**
 * Gets the specified user's posts, ordered by descending date.
 * @param { String } userUid the user id
 * @param { Number } limit the number of posts to get (default: 18)
 */
export const getUserPosts = async function getUserPosts(
  userUid,
  limit = 18,
  startAfter,
) {
  let posts = db
    .collection('posts')
    .where('userUid', '==', userUid)
    .limit(limit + 1) // Fetch one extra document to tiptoe if it is the last batch of docs
    .orderBy('dateCreated', 'desc');

  if (startAfter) {
    posts = posts.startAfter(startAfter);
  }

  const results = posts
    .get()
    .then(querySnapshot => {
      const data = [];
      querySnapshot.forEach(doc => data.push(parseData(doc.data())));

      /**
       * We fetched one extra document, if there is less or the same number of
       * documents than the actual provided limit, it means this is the last
       * batch of documents
       */
      const isLastBatch = data.length < limit + 1;

      /**
       * And if this is not the last batch, just remove the last document to have the
       * appropriate number of documents.
       */
      if (!isLastBatch) {
        data.pop();
      }

      // Get last document's snapshot to fetch next batch
      const snapshotLast = querySnapshot.docs[querySnapshot.docs.length - 2];
      return {
        data,
        snapshotLast,
        isLastBatch,
      };
    })
    .catch(error => {
      console.error('Error getUserPosts', error);
    });

  return results;
};

export const subscribeToUserPosts = function subscribeToUserPosts(params) {
  if (
    !check.all(
      check.map(params, {
        userUid: check.nonEmptyString,
        useData: check.function,
      }),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = params;
    console.error(error);
    throw error;
  }

  const { userUid, limit = 18, startAfter, useData } = params;

  let postsRef = db
    .collection('posts')
    .where('userUid', '==', userUid)
    .limit(limit + 1) // Fetch one extra document to tiptoe if it is the last batch of docs
    .orderBy('dateCreated', 'desc');

  if (startAfter) {
    postsRef = postsRef.startAfter(startAfter);
  }

  const unsuscribe = postsRef.onSnapshot(querySnapshot => {
    // Generate an object with the objects sorted by type of actions
    const isLastBatch = querySnapshot.size <= limit;

    const posts = querySnapshot.docChanges().reduce(
      (sortedData, change) => {
        const array = sortedData[change.type];
        const post = change.doc.data();
        const parsedPost = parseData(post);
        array.push(parsedPost);
        return sortedData;
      },
      {
        isLastBatch,
        added: [],
        // Get the last document snapshot to be able to subscribe to the next
        snapshotLast: querySnapshot.docs[querySnapshot.docs.length - 1],
        modified: [],
        removed: [],
      },
    );
    useData({ posts });
  });

  return unsuscribe;
};

export const verifyLift = async function verifyLift({
  uid,
  approved,
  feedback,
}) {
  if (
    !check.all(
      check.map(
        { uid, approved, feedback },
        {
          uid: check.nonEmptyString,
          approved: check.boolean,
          feedback: check.string,
        },
      ),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = { uid, approved, feedback };
    console.error(error);
    throw error;
  }

  const params = {
    approved,
    feedback,
  };
  const config = await getTokenedConfig();

  return axios
    .post(getApiEndpoint.posts.verifyLift(uid), params, config)
    .catch(console.error);
};

export const ignorePostReport = async function ignorePostReport(uid) {
  const config = await getTokenedConfig();

  return axios
    .post(getApiEndpoint.posts.ignorePostReport(uid), {}, config)
    .catch(console.error);
};
