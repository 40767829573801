import React, { Component } from 'react';
import { Container, Grid, Header, Loader, Message } from 'semantic-ui-react';
import update from 'immutability-helper';

import HeaderBar from '../components/navigation/HeaderBar';
import ActivityGrid from '../components/activities/ActivityGrid';

import {
  getActivities,
  updateActivity,
  updateVideoReference,
} from './../services/activities';
import ActivityDetails from '../components/activities/ActivityDetails';
import { removeFile } from '../services/files';

class StandardPage extends Component {
  state = {
    activities: [],
    activitySelected: '',
    isLoading: true,
    uidsBeingSaved: new Set(), // Uuid being saved
    form: {
      criteriaList: [],
      criteria: '',
      description: '',
      mediaUrl: '',
    },
    message: {
      content: '',
      success: true,
    },
  };

  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    return getActivities().then(activities => {
      this.setState({ activities, isLoading: false });
    });
  }

  requestUpdateVideoReference(videoRef) {
    this.setState(
      update(this.state, {
        form: {
          mediaUrl: { $set: videoRef.fullUrl },
        },
      }),
    );
    this.updateActivity(videoRef);
  }

  updateActivity = async videoRef => {
    const {
      activitySelected,
      activitySelected: { uid, name },
      form: { criteriaList, description },
      uidsBeingSaved,
    } = this.state;

    if (uidsBeingSaved.has(uid)) {
      return;
    }

    const addUidToSaving = {
      uidsBeingSaved: { $add: [uid] },
    };
    const removeUidFromSaving = { uidsBeingSaved: { $remove: [uid] } };

    try {
      const data = {
        ...activitySelected,
        description,
        criteria: criteriaList,
      };

      this.setState(update(this.state, addUidToSaving));

      if (videoRef) {
        const { fullUrl, path } = videoRef;
        const data = {
          uid,
          fullUrl,
          path,
        };

        const { mediaRef } = activitySelected;

        try {
          // Update reference to the new video
          await updateVideoReference(data);

          // Remove previous file from storage
          await removeFile(mediaRef);
        } catch (internalError) {
          // Remove new video if it didn't manage to update reference
          await removeFile(path);

          // Replace the shown video with the previous one
          this.setState(
            update(this.state, {
              form: {
                mediaUrl: { $set: '' },
              },
            }),
          );

          throw internalError;
        }
      } else {
        await updateActivity(uid, data);
      }

      await this.refreshData();

      this.setState(
        update(this.state, {
          ...removeUidFromSaving,
          message: {
            content: { $set: `Activity '${name}' successfully updated!` },
            success: { $set: true },
          },
        }),
      );
    } catch (error) {
      console.error(error);

      this.setState(
        update(this.state, {
          ...removeUidFromSaving,
          message: {
            content: { $set: error.message || error.reason || error.name },
            success: { $set: false },
          },
        }),
      );
    }
  };

  selectActivity = activitySelected => {
    const { description, criteria: criteriaList } = activitySelected;

    this.setState(
      update(this.state, {
        activitySelected: { $set: activitySelected },
        form: {
          criteria: { $set: '' },
          criteriaList: { $set: criteriaList },
          description: { $set: description },
        },
      }),
    );
  };

  handleChangeInput = ({ target: { name, value } }) => {
    this.setState(
      update(this.state, {
        form: {
          [name]: { $set: value },
        },
      }),
    );
  };

  handleFinishUploadVideo = videoRef => {
    this.requestUpdateVideoReference(videoRef);
  };

  removeCriteria = (evt, { content }) => {
    const { form: { criteriaList } } = this.state;

    const index = criteriaList.indexOf(content);
    if (index !== -1) {
      this.setState(
        update(this.state, {
          form: { criteriaList: { $splice: [[index, 1]] } },
        }),
      );
    }
  };

  addCriteria = () => {
    const { form: { criteria } } = this.state;

    if (criteria) {
      this.setState(
        update(this.state, {
          form: {
            criteriaList: { $push: [criteria] },
            criteria: { $set: '' },
          },
        }),
      );
    }
  };

  renderMessage() {
    const { message: { content, success } } = this.state;

    return (
      <Message positive={success} negative={!success}>
        {content}
      </Message>
    );
  }

  render() {
    const {
      isLoading,
      activitySelected,
      activities,
      form,
      uidsBeingSaved,
      message,
    } = this.state;

    return [
      <HeaderBar key="header" as="header" />,
      <Container key="main-content" as="main" className="main" fluid>
        <Loader inline="centered" active={isLoading} size="huge" />
        {!isLoading && (
          <div className="padded">
            {message.content && this.renderMessage()}
            <Header as="h2">Activities</Header>
            <Grid columns={2} divided={!!activitySelected}>
              <Grid.Row>
                <Grid.Column width={8}>
                  <ActivityGrid
                    selectedActivity={activitySelected}
                    activities={activities}
                    handleActivity={this.selectActivity}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  {activitySelected && (
                    <ActivityDetails
                      activity={activitySelected}
                      addCriteria={this.addCriteria}
                      removeCriteria={this.removeCriteria}
                      updateActivity={this.updateActivity}
                      onChangeInput={this.handleChangeInput}
                      onFinishUploadVideo={this.handleFinishUploadVideo}
                      form={form}
                      loading={uidsBeingSaved.has(activitySelected.uid)}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
      </Container>,
    ];
  }
}

export default StandardPage;
