import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header, Menu } from 'semantic-ui-react';

const propTypes = {
  children: PropTypes.node,
};

const SidebarLayout = ({ children }) => (
  <div className="fp-sidebar-layout">
    <Menu
      className="fp-sidebar"
      as="nav"
      size="large"
      fixed="left"
      borderless
      vertical
      inverted
    >
      <Menu.Item header>
        <Header className="fp-title" as="h1" textAlign="center" size="large">
          FLEXPIT
        </Header>
      </Menu.Item>

      <Menu.Item name="overview">
        <NavLink
          className="link -block"
          activeClassName="-active"
          to="/overview"
        >
          Overview
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          className="link -block"
          activeClassName="-active"
          to="/standard"
        >
          FLEXPIT Standard
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink className="link -block" activeClassName="-active" to="/users">
          Users
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          className="link -block"
          activeClassName="-active"
          to="/notifications"
        >
          Notifications
        </NavLink>
      </Menu.Item>
    </Menu>

    <div className="main-container">{children}</div>
  </div>
);

SidebarLayout.propTypes = propTypes;

export default SidebarLayout;
