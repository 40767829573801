import React, { Component } from 'react';
import {
  Container,
  Header,
  Menu,
  Button,
  Label,
  Loader,
} from 'semantic-ui-react';

import HeaderBar from '../components/navigation/HeaderBar';
import PendingTrainerCertificationPage from './PendingTrainerCertificationPage';
import VerifyLiftPage from './VerifyLiftPage';
import AbusePage from './AbusePage';
import PendingProfileVerificationPage from './PendingProfileVerificationPage';
import { getAbuses, getUnverifiedLifts } from '../services/posts';
import update from 'immutability-helper';
import {
  getPendingCertificationTrainers,
  getPendingVerificationUsers,
} from '../services/users';

const sectionTitles = {
  lift: 'Verify lift requests',
  abuse: 'Abuse reports',
  trainer: 'Trainer requests',
  profile: 'Profile verification requests',
};

class NotificationPage extends Component {
  headerBarPortalNodeId = 'notifications-page-portal';

  state = {
    active: '',
    notifications: {
      isLoading: true,
      abuses: undefined,
      liftsToVerify: undefined,
      trainersToCertify: undefined,
      profilesToVerify: undefined,
    },
  };

  componentDidMount() {
    const { match: { params: { filter } } } = this.props;

    this.getNotifications();
    this.mountNode = document.getElementById(this.headerBarPortalNodeId);
    this.setState({ active: filter || 'lift' });
  }

  async getNotifications() {
    const { notifications: { isLoading } } = this.state;

    if (!isLoading) {
      this.setState(
        update(this.state, {
          notification: {
            isLoading: { $set: true },
          },
        }),
      );
    }
    const [
      abuses,
      liftsToVerify,
      trainersToCertify,
      profilesToVerify,
    ] = await Promise.all([
      getAbuses(),
      getUnverifiedLifts(),
      getPendingCertificationTrainers(),
      getPendingVerificationUsers(),
    ]);

    this.setState(
      update(this.state, {
        notifications: {
          $set: {
            abuses: abuses.length,
            liftsToVerify: liftsToVerify.length,
            trainersToCertify: trainersToCertify.length,
            profilesToVerify: profilesToVerify.length,
            isLoading: false,
          },
        },
      }),
    );
  }

  handleActive(active) {
    this.setState({ active });
  }

  handleNotificationUpdate = (type, nb) => {
    this.setState(
      update(this.state, {
        notifications: {
          [type]: { $set: nb },
        },
      }),
    );
  };

  render() {
    const { active, notifications } = this.state;

    const notificationLoader = (
      <Loader size="mini" active={notifications.isLoading} inline inverted />
    );

    return [
      <HeaderBar key="header-bar" as="header">
        <Menu.Item id={this.headerBarPortalNodeId} className="-fluid -centre" />
      </HeaderBar>,
      <Container as="main" key="main-content" className="main" fluid>
        <div className="padded">
          <Header as="h2">
            Notifications
            <div className="button-list">
              <Button
                className="_relative"
                onClick={() => this.handleActive('lift')}
                secondary
                circular
                toggle
                basic={active !== 'lift'}
                active={active === 'lift'}
              >
                <Label
                  color={notifications.liftsToVerify === 0 ? 'green' : 'red'}
                  floating
                  circular
                >
                  {notificationLoader}
                  {!notifications.isLoading && notifications.liftsToVerify}
                </Label>
                Verify lift
              </Button>

              <Button
                className="_relative"
                onClick={() => this.handleActive('abuse')}
                secondary
                circular
                toggle
                basic={active !== 'abuse'}
                active={active === 'abuse'}
              >
                Abuse
                <Label
                  color={notifications.abuses === 0 ? 'green' : 'red'}
                  floating
                  circular
                >
                  {notificationLoader}
                  {!notifications.isLoading && notifications.abuses}
                </Label>
              </Button>

              <Button
                className="_relative"
                onClick={() => this.handleActive('trainer')}
                secondary
                circular
                toggle
                basic={active !== 'trainer'}
                active={active === 'trainer'}
              >
                Trainer Certification Requests
                <Label
                  color={
                    notifications.trainersToCertify === 0 ? 'green' : 'red'
                  }
                  floating
                  circular
                >
                  {notificationLoader}
                  {!notifications.isLoading && notifications.trainersToCertify}
                </Label>
              </Button>

              <Button
                className="_relative"
                onClick={() => this.handleActive('profile')}
                secondary
                circular
                toggle
                basic={active !== 'profile'}
                active={active === 'profile'}
              >
                Profile Verification Requests
                <Label
                  color={notifications.profilesToVerify === 0 ? 'green' : 'red'}
                  floating
                  circular
                >
                  {notificationLoader}
                  {!notifications.isLoading && notifications.profilesToVerify}
                </Label>
              </Button>
            </div>
          </Header>
          <Header as="h2" size="small">
            {sectionTitles[active]}
          </Header>
        </div>

        {active === 'lift' && (
          <VerifyLiftPage
            mountNode={this.mountNode}
            onNotificationNumberChange={this.handleNotificationUpdate}
          />
        )}
        {active === 'trainer' && (
          <PendingTrainerCertificationPage
            mountNode={this.mountNode}
            onNotificationNumberChange={this.handleNotificationUpdate}
          />
        )}
        {active === 'abuse' && (
          <AbusePage
            mountNode={this.mountNode}
            onNotificationNumberChange={this.handleNotificationUpdate}
          />
        )}
        {active === 'profile' && (
          <PendingProfileVerificationPage
            mountNode={this.mountNode}
            onNotificationNumberChange={this.handleNotificationUpdate}
          />
        )}
      </Container>,
    ];
  }
}

export default NotificationPage;
