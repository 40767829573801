import check from 'check-types';
import { db } from '../modules/firebase';

/*
 * All activties
 */
export const getActivities = async () => {
  try {
    const activities = db
      .collection('activities')
      .get()
      .then(function(querySnapshot) {
        const data = [];
        querySnapshot.forEach(function(doc) {
          let document = doc.data();
          document.uid = doc.id;
          data.push(document);
        });
        return data;
      });
    return activities;
  } catch (e) {
    console.log('Error getActivities => ' + e);
  }
};

export const updateActivity = async (uid, data) => {
  const { name, description, type, criteria, mediaRef } = data;

  const authorisedData = {
    name,
    criteria,
    description,
    type,
    mediaRef,
  };

  try {
    await db
      .collection('activities')
      .doc(uid)
      .update(authorisedData);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const updateVideoReference = function updateVideoReference(activity) {
  if (
    !check.all(
      check.map(activity, {
        uid: check.string,
        fullUrl: check.string,
        path: check.string,
      }),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = activity;
    throw error;
  }

  const { uid, fullUrl, path } = activity;

  const updateQuery = {
    mediaType: 'V',
    mediaUrl: fullUrl,
    mediaRef: path,
  };

  return db
    .collection('activities')
    .doc(uid)
    .update(updateQuery)
    .catch(error => {
      console.error(error);
      throw error;
    });
};
