import React, { Component } from 'react';

import { getAbuses, getUnverifiedLifts } from './../../services/posts';
import {
  getPendingCertificationTrainers,
  getPendingVerificationUsers,
} from './../../services/users';

import NotificationLine from './NotificationLine';
import { injectParamsIntoRoute, routes } from '../../modules/routes';
import { pluralise } from '../../modules/humanise';

const urlLiftNotification = injectParamsIntoRoute(routes.notifications, {
  filter: 'lift',
});
const urlAbuseNotification = injectParamsIntoRoute(routes.notifications, {
  filter: 'abuse',
});
const urlTrainerCertificationNotification = injectParamsIntoRoute(
  routes.notifications,
  { filter: 'trainer' },
);
const urlProfileNotification = injectParamsIntoRoute(routes.notifications, {
  filter: 'profile',
});

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trainers: [],
      lifts: [],
      abuses: [],
      athletes: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const [abuses, lifts, trainers, athletes] = await Promise.all([
      getAbuses(),
      getUnverifiedLifts(),
      getPendingCertificationTrainers(),
      getPendingVerificationUsers(),
    ]);

    this.setState({
      abuses,
      lifts,
      trainers,
      athletes,
      loading: false,
    });
  }

  render() {
    const { lifts, abuses, trainers, athletes, loading } = this.state;

    return (
      <div className="notification-list">
        <NotificationLine
          loading={loading}
          notifications={lifts.length}
          message={pluralise('Lift Verification Request', lifts.length)}
          link={urlLiftNotification}
        />
        <NotificationLine
          loading={loading}
          notifications={abuses.length}
          message={pluralise('Abuse Report', abuses.length)}
          link={urlAbuseNotification}
        />
        <NotificationLine
          loading={loading}
          notifications={trainers.length}
          message={pluralise('Trainer Certification Request', trainers.length)}
          link={urlTrainerCertificationNotification}
        />
        <NotificationLine
          loading={loading}
          notifications={athletes.length}
          message={pluralise('Profile Verification Request', athletes.length)}
          link={urlProfileNotification}
        />
      </div>
    );
  }
}

export default Notifications;
