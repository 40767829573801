import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import LiftTable from '../components/lifts/LiftTable';

import { subscribeToUnverifiedLifts } from '../services/posts';
import { getUser } from '../services/users';
import update from 'immutability-helper';
import { createSelector } from 'reselect';
import FilterInput from '../components/FilterInput';

/**
 * Selects all lifts from the state, inject their data and their user.
 */
const getLiftsWithUser = createSelector(
  [state => state.data.lifts, state => state.data.users],
  (liftMap, userMap) => {
    const basicLifts = [...liftMap.values()];

    const lifts = basicLifts.map(lift => ({
      ...lift,
      user: userMap.get(lift.userUid),
    }));

    return lifts;
  },
);

/**
 * Selects the filtered lifts, inject their data and their user.
 */
const getFilteredLiftsWithUser = createSelector(
  [
    state => state.filteredLifts,
    state => state.data.lifts,
    state => state.data.users,
  ],
  (uidsMap, liftMap, userMap) => {
    const lifts = [...uidsMap.values()].map(uid => {
      const lift = liftMap.get(uid);
      return {
        ...lift,
        user: userMap.get(lift.userUid),
      };
    });

    return lifts;
  },
);

class VerifyLiftPage extends Component {
  static propTypes = {
    mountNode: PropTypes.object.isRequired,
    onNotificationNumberChange: PropTypes.func,
  };

  /**
   * All actual data is stored in the data object, no duplicate anywhere. When
   * referencing data, we use the ids.
   */
  state = {
    data: {
      lifts: new Map(), // (lift.uid => lift)
      users: new Map(), // (user.uid => user)
    },
    filteredLifts: new Set(), // List of ids
    isLoading: true,
  };

  componentDidMount() {
    this.subscribeToLifts();
  }

  componentWillUnmount() {
    if (this.unsuscribeFromLifts) {
      this.unsuscribeFromLifts();
    }
  }

  subscribeToLifts() {
    this.unsuscribeFromLifts = subscribeToUnverifiedLifts(
      this.handleReceiveLifts,
    );
  }

  /**
   * Handles the receiving of lift data on each realtime snapshot update.
   * This will add the new lifts to the state, update existing lifts, removes removed
   * lifts, fetches and add the necessary users.
   */
  handleReceiveLifts = async ({ lifts }) => {
    const { data: { users } } = this.state;

    /**
     * Store all promises into an object so that we can wait for them once
     * we have looped through all the data.
     */
    const extraDataPromises = new Map();

    const usersToAdd = [];

    /**
     * The folowing functions return arrays in this structure [uid, data]. The
     * reason behind it is that we have a Map in the state, and `immutability-helper`
     * uses this format for the $add operator.
     */

    const liftsToAdd = lifts.added.map(lift => {
      const { uid, userUid } = lift;

      // Fetch user if not already in cache
      if (!users.has(userUid) && !extraDataPromises.has(userUid)) {
        const promise = getUser(userUid).then(user =>
          usersToAdd.push([userUid, user]),
        );
        extraDataPromises.set(userUid, promise);
      }

      // New entry for map (uid => lift);
      return [uid, lift];
    });

    const liftsToUpdate = lifts.modified.map(lift => [lift.uid, lift]);
    const liftsToRemove = lifts.removed.map(({ uid }) => uid);

    // Waiting for the user data before continuing further, we need it to update the state
    await Promise.all(extraDataPromises.values());

    const query = {
      data: {
        lifts: {
          $add: liftsToAdd.concat(liftsToUpdate),
          $remove: liftsToRemove,
        },
        users: {
          $add: usersToAdd,
        },
      },
      filteredLifts: { $remove: liftsToRemove },
      isLoading: { $set: false },
    };

    this.setState(update(this.state, query), () => {
      // Notify container
      const { onNotificationNumberChange } = this.props;
      if (onNotificationNumberChange) {
        const { data: { lifts } } = this.state;
        const nbNotificationLift = lifts.size;
        onNotificationNumberChange('liftsToVerify', nbNotificationLift);
      }
    });
  };

  /**
   * Handles the newly filtered uids from the `FilterInput`.
   */
  handleFilteredData = uids => {
    this.setState({ filteredLifts: new Set(uids) });
  };

  renderPortalSearchBar() {
    const { mountNode } = this.props;

    const lifts = getLiftsWithUser(this.state);

    return ReactDOM.createPortal(
      <FilterInput
        data={lifts}
        fields={['activity', 'caption']}
        onFilter={this.handleFilteredData}
        placeholder="Search Lifts (activity, caption)"
      />,
      mountNode,
    );
  }

  render() {
    const { isLoading } = this.state;

    const filteredLifts = getFilteredLiftsWithUser(this.state);

    return (
      <Fragment>
        {this.renderPortalSearchBar()}
        <LiftTable posts={filteredLifts} refreshPosts={this.refreshPosts} />
        <Loader active={isLoading} inline="centered" size="huge" />
      </Fragment>
    );
  }
}

export default VerifyLiftPage;
