import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Loader, Dimmer } from 'semantic-ui-react';
import _ from 'lodash';

import Slicon from '../icons/Slicon';

const propTypes = {
  isLoading: PropTypes.bool,
  files: PropTypes.array,
  column: PropTypes.object,
  direction: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
};

const defaultProps = {
  files: [],
  isLoading: false,
};

class DocumentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      direction: null,
      files: props.files,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ files: nextProps.files });
  }

  handleSort = clickedColumn => () => {
    const { column, files, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        users: _.sortBy(files, [clickedColumn]),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      users: files.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  renderBody(files) {
    return files.map((file, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>{file.name}</Table.Cell>
          <Table.Cell>{file.timeCreated}</Table.Cell>
          <Table.Cell>{this.renderView(file)}</Table.Cell>
          <Table.Cell>{this.renderActions(file)}</Table.Cell>
        </Table.Row>
      );
    });
  }

  renderView(file) {
    return (
      <Slicon
        name="eye"
        className="icon"
        onClick={() => window.open(file.downloadURLs[0])}
      />
    );
  }

  renderActions(file) {
    const { onRemove } = this.props;
    return (
      <Button
        icon={<Slicon name="trash" className="icon" />}
        onClick={onRemove}
        value={file.fullPath}
      />
    );
  }

  render() {
    const { isLoading, onRemove, ...props } = this.props;
    const { files, column, direction } = this.state;

    return (
      <Fragment>
        <Dimmer active={isLoading} inverted>
          <Loader size="medium">Loading...</Loader>
        </Dimmer>
        {files && files.length > 0 ? (
          <Table
            className="-borderless"
            textAlign={'center'}
            sortable
            {...props}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'username' ? direction : null}
                  onClick={this.handleSort('username')}
                >
                  File Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'firstName' ? direction : null}
                  onClick={this.handleSort('firstName')}
                >
                  Date Uploaded
                </Table.HeaderCell>
                <Table.HeaderCell>View</Table.HeaderCell>
                <Table.HeaderCell>Delete</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderBody(files)}</Table.Body>
          </Table>
        ) : (
          <p className="nodatamessage">
            No document uploaded by the user available.
          </p>
        )}
      </Fragment>
    );
  }
}

DocumentTable.propTypes = propTypes;
DocumentTable.defaultProps = defaultProps;

export default DocumentTable;
