/**
 * Parses some data to transform FireStore timestamp fields to system Date.
 * @param { object } data
 */
const parseData = function parseData({ dateCreated, dateUpdated, ...data }) {
  return {
    ...data,
    dateCreated: dateCreated && dateCreated.toDate(),
    dateUpdate: dateUpdated && dateUpdated.toDate(),
  };
};

export { parseData };
