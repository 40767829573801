import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card } from 'semantic-ui-react';

const propTypes = {
  activities: PropTypes.array,
  handleActivity: PropTypes.func.isRequired,
};

const defaultProps = {
  activities: [],
};

const ActivityGrid = function ActivityGrid({
  activities,
  selectedActivity,
  handleActivity,
}) {
  const { uid: selectedUid } = selectedActivity || {};
  return (
    <div className="card-grid">
      {activities.map(activity => (
        <Card
          key={activity.uid}
          className={classNames(
            '-square',
            '-centred',
            '-whitey',
            selectedUid === activity.uid && '-active',
          )}
          onClick={() => handleActivity(activity)}
        >
          <Card.Content>
            <Card.Header textAlign="center">{activity.name}</Card.Header>
          </Card.Content>
        </Card>
      ))}
    </div>
  );
};

ActivityGrid.propTypes = propTypes;
ActivityGrid.defaultProps = defaultProps;

export default ActivityGrid;
