import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Table } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';

import ScrollingModal from '../ScrollingModal';
import PostItem from './../posts/PostItem';
import Weight from '../Weight';
import { parseText } from '../../modules/humanise';

const propTypes = {
  posts: PropTypes.array,
  column: PropTypes.object,
  direction: PropTypes.object,
};

const defaultProps = {
  posts: [],
};

class LiftTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      column: null,
      direction: null,
      posts: props.posts,
      postSelected: null,
      isModalOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { postSelected } = this.state;

    this.setState({ posts: nextProps.posts });

    // Close modal if the currently selected post does not exist anymore
    if (
      postSelected &&
      !nextProps.posts.find(post => post.uid === postSelected.uid)
    ) {
      this.setState({ isModalOpen: false, postSelected: null });
    }
  }

  handlePost(postSelected) {
    this.setState({ postSelected, isModalOpen: true });
  }

  renderBody(posts) {
    return posts.map(post => {
      if (post) {
        return (
          <Table.Row
            key={post.uid}
            onClick={() => this.handlePost(post)}
            style={{ cursor: 'pointer' }}
          >
            <Table.Cell>{moment(post.dateCreated).format('LL')}</Table.Cell>
            <Table.Cell>{post.user.username}</Table.Cell>
            <Table.Cell>{post.activity}</Table.Cell>
            <Table.Cell>{parseText(post.caption)}</Table.Cell>
            <Table.Cell>
              <Weight>{post.weight}</Weight>
            </Table.Cell>
            <Table.Cell>{post.repsNo}</Table.Cell>
          </Table.Row>
        );
      }

      return null;
    });
  }

  handleSort = clickedColumn => () => {
    const { column, posts, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        posts: _.sortBy(posts, [clickedColumn]),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      posts: posts.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  closeModal = () => this.setState({ isModalOpen: false, postSelected: null });

  render() {
    const { onRemove } = this.props;
    const { posts, column, direction, postSelected, isModalOpen } = this.state;

    return [
      <Table
        key="table"
        textAlign="left"
        className="-borderless"
        selectable
        sortable
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === 'dateCreated' ? direction : null}
              onClick={this.handleSort('dateCreated')}
            >
              Date Created
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'user.username' ? direction : null}
              onClick={this.handleSort('user.username')}
            >
              Username
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'activity' ? direction : null}
              onClick={this.handleSort('activity')}
            >
              Activity
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'caption' ? direction : null}
              onClick={this.handleSort('caption')}
            >
              Caption
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'weight' ? direction : null}
              onClick={this.handleSort('weight')}
            >
              Weight
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'repsNo' ? direction : null}
              onClick={this.handleSort('repsNo')}
            >
              Rep.
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.renderBody(posts)}</Table.Body>
      </Table>,
      <ScrollingModal
        key="post-modal"
        size="tiny"
        className="-notpadded"
        open={isModalOpen}
        onClose={this.closeModal}
      >
        <Modal.Content>
          {postSelected && (
            <PostItem
              post={postSelected}
              onVerifyLift={this.closeModal}
              onRemove={() => onRemove()}
            />
          )}
        </Modal.Content>
      </ScrollingModal>,
    ];
  }
}

LiftTable.propTypes = propTypes;
LiftTable.defaultProps = defaultProps;

export default LiftTable;
