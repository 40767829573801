import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Image, Checkbox } from 'semantic-ui-react';
import update from 'immutability-helper';

import { logout, getLoggedInUid } from '../../services/authentication';
import withLoggedInUser from '../higher-order/withLoggedInUser';
import { updateUser } from '../../services/users';

class HeaderBar extends Component {
  state = {
    updateProfile: {
      error: undefined,
    },
    isDropdownOpen: false,
  };

  async requestUpdateUnit(type, unit) {
    const {
      currentUserState: {
        profile: { units: oldUnit = { weight: 'lbs', height: 'ft' } },
        refreshCurrentUser,
      },
    } = this.props;

    refreshCurrentUser({ units: { [type]: { $set: unit } } });

    let error = null;
    const params = {
      units: {
        ...oldUnit,
        [type]: unit,
      },
      uid: getLoggedInUid(),
    };
    try {
      await updateUser(params);
    } catch (internalError) {
      error = internalError;
      refreshCurrentUser({ unit: { $set: oldUnit } });
    }

    this.setState(
      update(this.state, {
        updateProfile: {
          error: { $set: error },
        },
      }),
    );
  }

  handleChangeUnit = (evt, data) => {
    evt.stopPropagation();
    const { name, checked } = data || evt.target;

    let unit;
    if (name === 'weight') {
      unit = checked ? 'lbs' : 'kg';
    } else if (name === 'height') {
      unit = checked ? 'ft' : 'cm';
    }
    this.requestUpdateUnit(name, unit);
  };

  handleToggleDropdown = () =>
    this.setState(update(this.state, { isDropdownOpen: value => !value }));

  render() {
    const {
      children,
      currentUserState: { profile },
      ...otherProps
    } = this.props;
    const { isDropdownOpen } = this.state;

    let trigger;

    if (profile) {
      const { photoUrl, firstName, lastName, username } = profile;
      const userString = (firstName && `${firstName} ${lastName}`) || username;
      trigger = (
        <span>
          {photoUrl && <Image avatar src={photoUrl} />} {userString}
        </span>
      );
    }

    const { units: { weight = 'lbs', height = 'ft' } = {} } = profile || {};

    return (
      <Fragment>
        <Menu
          as="header"
          className="header-bar"
          attached="top"
          borderless
          {...otherProps}
        >
          {children}
          {profile && (
            <Menu.Menu position="right">
              <Dropdown
                item
                trigger={trigger}
                open={isDropdownOpen}
                onClick={this.handleToggleDropdown}
              >
                <Dropdown.Menu style={{ zIndex: 103 }}>
                  {profile && (
                    <Dropdown.Item>
                      <Checkbox
                        label="Convert weights to lbs"
                        checked={weight === 'lbs'}
                        name="weight"
                        onChange={this.handleChangeUnit}
                      />
                    </Dropdown.Item>
                  )}
                  {profile && (
                    <Dropdown.Item>
                      <Checkbox
                        label="Convert heights to feet"
                        name="height"
                        checked={height === 'ft'}
                        onChange={this.handleChangeUnit}
                      />
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          )}
        </Menu>
        {isDropdownOpen && (
          <div
            className="popup-mask"
            onClick={this.handleToggleDropdown}
            aria-hidden
          />
        )}
      </Fragment>
    );
  }
}

HeaderBar.propTypes = {
  children: PropTypes.node,
};

export default withLoggedInUser(HeaderBar);
