import React, { Component, Fragment } from 'react';
import { shape, bool, func, string } from 'prop-types';
import {
  Loader,
  Image,
  Button,
  Confirm,
  List,
  Comment,
  Dimmer,
  Message,
} from 'semantic-ui-react';
import update from 'immutability-helper';
import moment from 'moment';
import classNames from 'classnames';

import CommentItem from './CommentItem';
import Weight from '../Weight';

import {
  getComments,
  verifyLift,
  removePost,
  ignorePostReport,
} from './../../services/posts';
import VerifyLiftForm from '../lifts/VerifyLiftForm';
import { getUser } from '../../services/users';
import { getProfile } from '../../services/authentication';
import Slicon from '../icons/Slicon';

const apiKeys = {
  getVerifiedCoach: 'get-verified-coach',
  verifyLift: 'verify-lift',
  removePost: 'remove-post',
  ignoreReport: 'ignore-report',
};

class PostItem extends Component {
  static propTypes = {
    post: shape({
      uid: string.isRequired,
      approved: bool,
      isLift: bool,
      verified: bool,
      status: string,
      verifiedByCoach: string,
    }).isRequired,
    onRemove: func,
    showComments: bool,
    onVerifyLift: func,
  };

  state = {
    comments: [],
    verifyingLift: {
      error: undefined,
      isFormOpen: false,
      form: {
        feedback: '',
        isApproved: undefined,
      },
      user: undefined,
    },
    removingPost: {
      error: undefined,
    },
    isConfirmOpen: false,
    isIgnoreConfirmationOpen: false,
    showComments: false,
    waitingApiResponse: '',
  };

  _isMounted = undefined;

  componentDidMount() {
    this._isMounted = true;

    getComments(this.props.post.uid).then(comments => {
      this.setState({ comments });
    });

    this.getVerifiedCoach();
  }

  componentWillReceiveProps(nextProps) {
    const { post } = this.props;

    // Refresh the displayed coach when changing post
    if (
      nextProps.post.verifiedByCoach &&
      nextProps.post.verifiedByCoach !== post.verifiedByCoach
    ) {
      this.getVerifiedCoach(nextProps);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    this._isMounted = false;
  }

  async getVerifiedCoach(props = this.props) {
    const { post: { verifiedByCoach } } = props;

    let user = null;
    let error;

    // Check if we have a uid provided, if not do not call API
    if (verifiedByCoach) {
      // Check if the coach who verified is the currently logged in user
      const loggedInUser = getProfile();
      if (verifiedByCoach === loggedInUser.uid) {
        user = loggedInUser;
      } else {
        // The user is different than the one logged in, need to fetch the data
        this.setState({ waitingApiResponse: apiKeys.getVerifiedCoach });

        try {
          user = await getUser(verifiedByCoach);
        } catch (internalError) {
          error = new Error(
            `Internal error: ${internalError.message || internalError.reason}`,
          );
          error.internalError = internalError;
        }
      }
    }

    // Update state with the data
    this.setState(
      update(this.state, {
        verifyingLift: {
          error: { $set: error },
          user: { $set: user },
        },
        waitingApiResponse: { $set: '' },
      }),
    );
  }

  /**
   * Removes the post.
   */
  async removePost() {
    const { post: { uid }, onRemove } = this.props;

    // Show loading state
    this.setState(
      update(this.state, {
        removingPost: {
          error: { $set: undefined },
        },
        waitingApiResponse: { $set: apiKeys.removePost },
      }),
    );

    let error = null;

    // Call API and catch error if one occurs
    try {
      await removePost(uid);
      if (onRemove) onRemove();
    } catch (internalError) {
      console.error(internalError, `when attempting to remove post ${uid}`);
      const { message, reason } = internalError;
      error = new Error(
        `Internal error: ${message || reason}, please try again later.`,
      );
    }

    // Stop loading state
    if (this._isMounted) {
      this.setState(
        update(this.state, {
          removingPost: {
            error: { $set: error },
          },
          waitingApiResponse: { $set: '' },
        }),
      );
    }
  }

  /**
   * Removes the post.
   */
  async ignoreReport() {
    const { post: { uid }, onRemove } = this.props;

    // Show loading state
    this.setState(
      update(this.state, {
        removingPost: {
          error: { $set: undefined },
        },
        waitingApiResponse: { $set: apiKeys.ignoreReport },
      }),
    );

    let error = null;

    // Call API and catch error if one occurs
    try {
      await ignorePostReport(uid);
      if (onRemove) onRemove();
    } catch (internalError) {
      console.error(
        internalError,
        `when attempting to ignore post report ${uid}`,
      );
      const { message, reason } = internalError;
      error = new Error(
        `Internal error: ${message || reason}, please try again later.`,
      );
    }

    // Stop loading state
    if (this._isMounted) {
      this.setState(
        update(this.state, {
          removingPost: {
            error: { $set: error },
          },
          waitingApiResponse: { $set: '' },
        }),
      );
    }
  }

  handleChangeVerifyForm = (
    { target: { name, value, checked, type } },
    callback,
  ) => {
    const $set = type === 'checkbox' ? checked : value;
    this.setState(
      update(this.state, {
        verifyingLift: {
          form: {
            [name]: { $set },
          },
        },
      }),
      callback,
    );
  };

  requestVerifyPost = async () => {
    const { post: { uid } } = this.props;
    const { verifyingLift: { form: { feedback, isApproved } } } = this.state;

    this.setState({ waitingApiResponse: apiKeys.verifyLift });
    try {
      const params = {
        uid,
        feedback,
        approved: isApproved,
      };

      await verifyLift(params);

      this.setState(
        update(this.state, {
          verifyingLift: {
            error: { $set: null },
            form: {
              feedback: { $set: '' },
            },
            isFormOpen: { $set: false },
          },
          waitingApiResponse: { $set: '' },
        }),
      );

      const { onVerifyLift } = this.props;
      if (onVerifyLift) onVerifyLift(isApproved);
    } catch (error) {
      console.error(error);
      this.setState(
        update(this.state, {
          verifyingLift: { error: { $set: error } },
          waitingApiResponse: { $set: '' },
        }),
      );
    }
  };

  clearError = () =>
    this.setState(
      update(this.state, {
        removingPost: {
          error: { $set: undefined },
        },
        verifyingLift: {
          error: { $set: undefined },
        },
      }),
    );

  handleCancel = () =>
    this.setState({ isConfirmOpen: false, isIgnoreConfirmationOpen: false });
  handleOpenConfirmRemove = () => this.setState({ isConfirmOpen: true });
  handleOpenConfirmIgnore = () =>
    this.setState({ isIgnoreConfirmationOpen: true });

  handleConfirmRemove = () => {
    this.removePost();
    this.setState({ isConfirmOpen: false });
  };

  handleConfirmIgnore = () => {
    this.ignoreReport();
    this.setState({ isIgnoreConfirmationOpen: false });
  };

  toggleComments = () =>
    this.setState({ showComments: !this.state.showComments });

  toggleVerifyingFormDisplay = () => {
    this.setState(
      update(this.state, { verifyingLift: { $toggle: ['isFormOpen'] } }),
    );
  };

  show = () => this.setState({ isConfirmOpen: true });

  renderComments() {
    const { comments, showComments } = this.state;

    return (
      <Comment.Group collapsed={!showComments}>
        {comments.map((comment, index) => (
          <CommentItem key={index} comment={comment} />
        ))}
      </Comment.Group>
    );
  }

  renderLiftStatus() {
    const { post: { feedback, isLift, status } } = this.props;

    const { verifyingLift: { user }, waitingApiResponse } = this.state;

    if (!isLift || status === 'unverified') {
      return undefined;
    }

    const isCorrect = status === 'correct';
    const approvedMessageProps = isCorrect
      ? {
          className: 'strong -positive',
          children: 'Approved',
        }
      : {
          className: 'strong -negative',
          children: 'Rejected',
        };

    let coachText;
    if (user !== undefined) {
      if (user === null) {
        coachText = 'Unknown';
      } else if (getProfile().uid === user.uid) {
        coachText = 'you';
      } else {
        coachText = `@${user.username}`;
      }
    }

    return (
      <span>
        <strong {...approvedMessageProps} /> by{' '}
        <strong className="strong">{coachText}</strong>
        {feedback && (
          <Fragment>
            {' '}
            (feedback: <q className="quote">{feedback}</q>)
          </Fragment>
        )}
        <Loader
          className={classNames(
            '-notinverted',
            waitingApiResponse !== apiKeys.getVerifiedCoach && '_hidden',
          )}
          active={waitingApiResponse === apiKeys.getVerifiedCoach}
          size="tiny"
          inline
        />
      </span>
    );
  }

  render() {
    const {
      post: {
        isLift,
        mediaType,
        mediaUrl,
        activity,
        repsNo,
        weight,
        fistBumpsNo,
        dateCreated,
        caption,
        commentsNo,
        status,
        reported,
      },
    } = this.props;

    const {
      isConfirmOpen,
      isIgnoreConfirmationOpen,
      showComments,
      verifyingLift: { error: errorLift, isFormOpen, form },
      removingPost,
      waitingApiResponse,
    } = this.state;

    const messageLift = errorLift
      ? {
          error: true,
          header: errorLift.toString(),
        }
      : undefined;

    const isUnverifiedLift = isLift && status === 'unverified';

    return (
      <article className="post-block">
        {mediaType === 'I' && <Image className="media" src={mediaUrl} />}
        {mediaType === 'V' && (
          <video
            className="media"
            src={mediaUrl}
            width="100%"
            height="500"
            controls
          />
        )}

        <div className="content">
          {removingPost.error && (
            <section className="section">
              <Message error>{removingPost.error.toString()}</Message>
            </section>
          )}

          <section className="section">
            <div className="button-list">
              {isUnverifiedLift && (
                <Button
                  toggle
                  primary={!isFormOpen}
                  basic={!isFormOpen}
                  negative={isFormOpen}
                  circular
                  loading={waitingApiResponse === apiKeys.verifyLift}
                  disabled={waitingApiResponse === apiKeys.verifyLift}
                  onClick={this.toggleVerifyingFormDisplay}
                >
                  {isFormOpen ? 'Stop' : 'Start'} verifying lift
                </Button>
              )}
              <Button
                className={classNames(isUnverifiedLift && '-tertiary')}
                basic={!isUnverifiedLift}
                circular={!isUnverifiedLift}
                negative
                loading={waitingApiResponse === apiKeys.removePost}
                disabled={waitingApiResponse === apiKeys.removePost}
                onClick={this.handleOpenConfirmRemove}
              >
                Remove post
              </Button>
              {reported && (
                <Button
                  className={classNames(isUnverifiedLift && '-tertiary')}
                  basic={!isUnverifiedLift}
                  circular={!isUnverifiedLift}
                  negative
                  loading={waitingApiResponse === apiKeys.ignoreReport}
                  disabled={waitingApiResponse === apiKeys.ignoreReport}
                  onClick={this.handleOpenConfirmIgnore}
                >
                  Ignore report
                </Button>
              )}
            </div>
          </section>

          <section className="section">
            {isLift && this.renderLiftStatus()}
          </section>

          {isFormOpen && (
            <section className="section">
              <VerifyLiftForm
                {...form}
                className="-vcentre"
                unstackable
                message={messageLift}
                onChange={this.handleChangeVerifyForm}
                onFocus={this.clearError}
                onSubmit={this.requestVerifyPost}
                loading={waitingApiResponse === apiKeys.verifyLift}
                disabled={waitingApiResponse === apiKeys.verifyLift}
              />
            </section>
          )}

          <section className="section">
            <List className="info-list" horizontal>
              {isLift && (
                <List.Item
                  className="-inline"
                  header={`${activity}: `}
                  description={
                    <List.Description>
                      {repsNo} Reps @ <Weight value={weight} />
                    </List.Description>
                  }
                />
              )}

              <List.Item
                description={`${fistBumpsNo} fistbump${
                  fistBumpsNo > 1 ? 's' : ''
                }`}
              />

              <List.Item
                description={moment(dateCreated).fromNow()}
                icon={<Slicon name="clock icon" />}
              />
            </List>

            <p className="caption">{caption}</p>
          </section>

          {commentsNo > 0 && (
            <section className="section">
              <button className="link" onClick={this.toggleComments}>
                {showComments ? 'Hide' : 'Read'} {commentsNo} comment{commentsNo >
                1
                  ? 's'
                  : ''}
              </button>

              {this.renderComments()}
            </section>
          )}
        </div>

        <Dimmer active={isConfirmOpen} onClickOutside={this.handleCancel}>
          <Confirm
            content="Are you sure you want to delete this post?"
            dimmer={false}
            open={isConfirmOpen}
            size="mini"
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirmRemove}
            confirmButton={{
              circular: true,
              negative: true,
              children: 'Delete this post',
            }}
            cancelButton={{
              basic: true,
              circular: true,
              secondary: true,
              children: 'Cancel',
            }}
          />
        </Dimmer>

        <Dimmer
          active={isIgnoreConfirmationOpen}
          onClickOutside={this.handleCancel}
        >
          <Confirm
            content="Are you sure you want to ignore this post report?"
            dimmer={false}
            open={isIgnoreConfirmationOpen}
            size="mini"
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirmIgnore}
            confirmButton={{
              circular: true,
              negative: true,
              children: 'Ignore post report',
            }}
            cancelButton={{
              basic: true,
              circular: true,
              secondary: true,
              children: 'Cancel',
            }}
          />
        </Dimmer>
      </article>
    );
  }
}

export default PostItem;
