import React from 'react';
import { shape, number, bool } from 'prop-types';
import { Statistic, Loader } from 'semantic-ui-react';
import { kifyNumber } from '../../modules/humanise';

const propTypes = {
  stats: shape({
    totalUsers: number,
    totalPosts: number,
    totalTrainers: number,
    totalAthletes: number,
  }),
  loading: bool,
};

const defaultProps = {
  stats: {},
  loading: false,
};

const StatsCards = function StatsCards({
  stats: { totalUsers, totalPosts, totalTrainers, totalAthletes },
  loading = false,
}) {
  const loader = (
    <Loader inline="centered" style={{ fontSize: '2rem' }} active={loading} />
  );
  return (
    <Statistic.Group className="-card" size="small" widths={4}>
      <Statistic color="green">
        <Statistic.Label>Total Users</Statistic.Label>
        <Statistic.Value>
          {loader}
          {!loading && totalUsers >= 10000
            ? kifyNumber(totalUsers)
            : totalUsers}
        </Statistic.Value>
      </Statistic>
      <Statistic color="green">
        <Statistic.Label>Total Posts</Statistic.Label>
        <Statistic.Value>
          {loader}
          {!loading && totalPosts >= 10000
            ? kifyNumber(totalPosts)
            : totalPosts}
        </Statistic.Value>
      </Statistic>
      <Statistic color="green">
        <Statistic.Label>Trainers</Statistic.Label>
        <Statistic.Value>
          {loader}
          {!loading && totalTrainers >= 10000
            ? kifyNumber(totalTrainers)
            : totalTrainers}
        </Statistic.Value>
      </Statistic>
      <Statistic color="green">
        <Statistic.Label>Professional Athletes</Statistic.Label>
        <Statistic.Value>
          {loader}
          {!loading && totalAthletes >= 10000
            ? kifyNumber(totalAthletes)
            : totalAthletes}
        </Statistic.Value>
      </Statistic>
    </Statistic.Group>
  );
};

StatsCards.propTypes = propTypes;
StatsCards.defaultProps = defaultProps;

export default StatsCards;
