import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import ClearableDropdown from '../ClearableDropdown';

const Filters = ({
  disabled = false,
  onChange,
  sportOptions = [],
  lvlOptions = [],
  values: { sport, sportLevel, verified, userType },
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8} className="button-list">
          <Button
            basic={verified !== true}
            secondary={verified !== true}
            compact
            toggle
            name="verified"
            active={verified === true}
            disabled={disabled}
            onClick={onChange}
            value={verified ? undefined : true}
            circular
          >
            Status: Verified
          </Button>

          <Button
            basic={!userType.has('Trainer')}
            secondary={!userType.has('Trainer')}
            compact
            toggle
            name="userType"
            active={userType.has('Trainer')}
            disabled={disabled}
            onClick={onChange}
            value="Trainer"
            circular
          >
            Type: Trainer
          </Button>

          <Button
            basic={!userType.has('Scout')}
            secondary={!userType.has('Scout')}
            compact
            toggle
            name="userType"
            active={userType.has('Scout')}
            disabled={disabled}
            onClick={onChange}
            value="Scout"
            circular
          >
            Type: Scout
          </Button>

          <Button
            basic={!userType.has('Casual')}
            secondary={!userType.has('Casual')}
            compact
            toggle
            name="userType"
            active={userType.has('Casual')}
            disabled={disabled}
            onClick={onChange}
            value="Casual"
            circular
          >
            Type: Casual
          </Button>

          <Button
            basic={!userType.has('Athlete')}
            secondary={!userType.has('Athlete')}
            compact
            toggle
            name="userType"
            active={userType.has('Athlete')}
            disabled={disabled}
            onClick={onChange}
            value="Athlete"
            circular
          >
            Type: Athlete
          </Button>
        </Grid.Column>

        <Grid.Column width={4}>
          <ClearableDropdown
            search
            selection
            name="sport"
            searchInput={{ type: 'text' }}
            onChange={onChange}
            options={sportOptions}
            placeholder="Sport"
            value={sport}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <ClearableDropdown
            search
            selection
            name="sportLevel"
            searchInput={{ type: 'text' }}
            onChange={onChange}
            options={lvlOptions}
            placeholder="Sport level"
            value={sportLevel}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Filters;
