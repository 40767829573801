import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Modal,
  Button,
  Message,
  Loader,
  Dimmer,
  Header,
} from 'semantic-ui-react';

import { getFileData, removeFile } from './../../services/files';
import {
  verifyProfile,
  removeVerificationDocuments,
  unverifyProfile,
} from './../../services/users';
import DocumentTable from '../documents/DocumentTable';
import update from 'immutability-helper';
import ReferenceForm from './ReferenceForm';
import ScrollingModal from '../ScrollingModal';

const propTypes = {
  userUid: PropTypes.string.isRequired,
  documents: PropTypes.array,
  onFinishVerification: PropTypes.func,
};

const defaultProps = {
  documents: [],
  onFinishVerification: undefined,
};

const jobMessages = {
  approve: 'Approving verification',
  decline: 'Declining verification',
  fetchFiles: 'Getting verification files',
  removeFile: 'Removing file',
};

class ProfileVerificationModal extends Component {
  state = {
    files: [],
    error: undefined,
    isDoingJob: 'fetchFiles',
    form: {
      feedback: '',
    },
  };

  componentDidMount() {
    this.fetchDocuments();
  }

  async fetchDocuments() {
    const { verificationDoc } = this.props;
    const { isDoingJob } = this.props;

    if (isDoingJob !== 'fetchFiles') {
      this.setState({ isDoingJob: 'fetchFiles' });
    }

    let files = [];
    let error = null;
    if (verificationDoc && verificationDoc.length) {
      try {
        files = await Promise.all(verificationDoc.map(getFileData));
      } catch (internalError) {
        error = new Error(
          `Internal error, could not read document (${internalError.message})`,
        );
        error.internalError = internalError;
      }
    }

    this.setState({ error, files, isDoingJob: '' });
  }

  async sendVerificationRequest(approved) {
    const { close, onFinishVerification, userUid, reference } = this.props;
    const { form: { feedback } } = this.state;

    let apiCall = unverifyProfile;
    let codeCall = 'decline';
    let removeReferal = Boolean(reference);

    if (approved) {
      apiCall = verifyProfile;
      codeCall = 'approve';
      removeReferal = undefined;
    }

    try {
      this.setState(
        update(this.state, {
          error: { $set: undefined },
          isDoingJob: { $set: codeCall },
        }),
      );

      const params = {
        feedback,
        removeReferal,
        uid: userUid,
      };

      await apiCall(params);

      this.setState(
        update(this.state, {
          error: { $set: null },
          isDoingJob: { $set: '' },
        }),
      );

      close();
      if (onFinishVerification) onFinishVerification(approved);
    } catch (internalError) {
      console.error(
        internalError,
        'ProfileVerificationModal > sendVerificationRequest()',
      );
      const { message, reason } = internalError;
      const error = new Error(`Internal error: (${reason || message})`);
      error.internalError = internalError;

      this.setState(
        update(this.state, {
          error: { $set: error },
          isDoingJob: { $set: '' },
        }),
      );
    }
  }

  async removeDocument(fileToDelete) {
    const { userUid } = this.props;
    const { files } = this.state;

    this.setState(
      update(this.state, {
        error: { $set: undefined },
        isDoingJob: { $set: 'removeFile' },
      }),
    );

    try {
      // Remove file from storage
      await removeFile(fileToDelete);

      // Remove file reference from user
      await removeVerificationDocuments({
        uid: userUid,
        documents: [fileToDelete],
      });

      // Remove file in state to hide it without refreshing, and finalise user interface feedback
      const indexRemove = files.findIndex(
        file => file.fullPath === fileToDelete,
      );

      this.setState(
        update(this.state, {
          error: { $set: null },
          files: { $splice: [[indexRemove, 1]] },
          isDoingJob: { $set: '' },
        }),
      );
    } catch (internalError) {
      const error = new Error(
        `Internal error: ${internalError.message || internalError.reason}`,
      );
      error.internalError = internalError;
      console.error(error, 'ProfileVerificationModal > removeDocument()');
      this.setState({ error, isDoingJob: '' });
    }
  }

  completeVerification = () => this.sendVerificationRequest(true);
  declineVerification = () => this.sendVerificationRequest(false);

  handleRemoveDoc = ({ target: { value } }) => {
    this.removeDocument(value);
  };

  handleFormChange = ({ target: { name, value } }) =>
    this.setState(
      update(this.state, {
        form: { [name]: { $set: value } },
      }),
    );

  render() {
    const {
      className,
      userType,
      close,
      verificationDoc,
      reference,
      userUid,
      setUserDocumentation,
      onFinishVerification,
      ...otherProps
    } = this.props;
    const {
      error,
      files,
      isLoading,
      isDoingJob,
      form: { feedback },
    } = this.state;

    return (
      <ScrollingModal
        className={className}
        onClose={!isDoingJob ? close : undefined}
        {...otherProps}
      >
        <Modal.Header>Verify profile</Modal.Header>
        <Modal.Content>
          <Dimmer active={Boolean(isDoingJob)} inverted>
            <Loader size="medium">{jobMessages[isDoingJob]}...</Loader>
          </Dimmer>

          <section className="section">
            <Header as="h4">Documents</Header>
            <DocumentTable
              isLoading={isLoading}
              files={files}
              onRemove={this.handleRemoveDoc}
            />

            <p className="information forcepadding">
              Once approved, all documents will be removed.
            </p>
          </section>

          {reference && (
            <section className="section">
              <Header as="h4">Reference</Header>
              <ReferenceForm data={reference} inputProps={{ readOnly: true }} />
            </section>
          )}

          <section className="section">
            <Header as="h4">Your feedback</Header>
            <Form className="forcepadding">
              <Form.Input
                autoFocus
                fluid
                label="Feedback"
                name="feedback"
                placeholder="e.g. Missing document, unreadable part..."
                onChange={this.handleFormChange}
                value={feedback}
              />
            </Form>
          </section>

          {error && <Message negative>{error.toString()}</Message>}
        </Modal.Content>
        <Modal.Actions>
          <Button
            circular
            className="-tertiary"
            onClick={!isDoingJob ? close : undefined}
            disabled={Boolean(isDoingJob)}
          >
            Close
          </Button>
          <Button
            circular
            basic
            secondary
            onClick={this.declineVerification}
            disabled={Boolean(isDoingJob)}
            loading={isDoingJob === 'decline'}
          >
            Decline verification
          </Button>
          <Button
            circular
            primary
            onClick={this.completeVerification}
            disabled={Boolean(isDoingJob)}
            loading={isDoingJob === 'approve'}
          >
            Complete verification
          </Button>
        </Modal.Actions>
      </ScrollingModal>
    );
  }
}

ProfileVerificationModal.propTypes = propTypes;
ProfileVerificationModal.defaultProps = defaultProps;

export default ProfileVerificationModal;
