import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [10, 25, 50, 100, 150].map(nb => ({
  key: nb,
  text: `${nb} rows`,
  value: nb,
}));

/**
 * A simple dropdown with predefined options for pagination.
 * @param { object } props
 */
const PaginationDropdown = function PaginationDropdown({ ...dropdownProps }) {
  return <Dropdown {...dropdownProps} options={options} />;
};

export default PaginationDropdown;
