import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Comment, Loader } from 'semantic-ui-react';
import moment from 'moment';

import { getUser } from './../../services/users';
import { parseText } from './../../modules/humanise';

const propTypes = {
  comment: PropTypes.object.isRequired,
};

const defaultProps = {};

class CommentItem extends Component {
  state = {
    user: undefined,
    isLoading: true,
  };

  componentDidMount() {
    const { comment: { userUid } } = this.props;

    getUser(userUid).then(user => {
      this.setState({ user, isLoading: false });
    });
  }

  renderUsername = () => {
    const { user, isLoading } = this.state;

    if (!isLoading && user && user.username) {
      return user.username;
    }

    if (!isLoading && !user) {
      return '[User Deleted]';
    }

    return '';
  };

  render() {
    const { comment: { dateCreated, text } } = this.props;
    const { isLoading } = this.state;

    return (
      <Comment>
        <Comment.Author as="strong">
          <Loader
            inline
            size="tiny"
            className={classNames('-notinverted', !isLoading && '_hidden')}
            active={isLoading}
          />
          {this.renderUsername()}
        </Comment.Author>
        <Comment.Metadata>{moment(dateCreated).fromNow()}</Comment.Metadata>
        <Comment.Text>{parseText(text)}</Comment.Text>
      </Comment>
    );
  }
}

CommentItem.propTypes = propTypes;
CommentItem.defaultProps = defaultProps;

export default CommentItem;
