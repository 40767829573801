import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Loader } from 'semantic-ui-react';

import LiftTable from '../components/lifts/LiftTable';

import { getAbuses } from '../services/posts';
import update from 'immutability-helper';
import FilterInput from '../components/FilterInput';
import { createSelector } from 'reselect';

class AbusePage extends Component {
  static propTypes = {
    mountNode: PropTypes.object.isRequired,
    onNotificationNumberChange: PropTypes.func,
  };

  state = {
    posts: new Map(),
    filteredPosts: new Set(),
    isLoading: true,
    error: undefined,
  };

  /**
   * Gets all the posts in the state.
   */
  getPosts = createSelector(
    state => state.posts,
    postsMap => [...postsMap.values()],
  );

  /**
   * Selects filtered posts from the state.
   */
  getFilteredPosts = createSelector(
    state => state.posts,
    state => state.filteredPosts,
    (postsMap, filteredUids) => {
      return [...filteredUids].map(uid => postsMap.get(uid));
    },
  );

  componentDidMount() {
    this.refreshPosts();
  }

  refreshPosts() {
    getAbuses().then(posts => {
      const { onNotificationNumberChange } = this.props;
      const { filteredPosts } = this.state;

      // Prepare users to be inserted in a Map<Uid, Lift>
      const uidPosts = posts.map(post => [post.uid, post]);
      const postsMap = new Map(uidPosts);

      /**
       * Create a list of removed post uids by taking the previous filtered post
       * list, and removing posts that are in the freshly fetched post list.
       */
      const removedPostUids = [...filteredPosts].filter(
        uid => !postsMap.has(uid),
      );

      if (onNotificationNumberChange) {
        const nbNotifAbuse = postsMap.size;
        onNotificationNumberChange('abuses', nbNotifAbuse);
      }

      this.setState(
        update(this.state, {
          posts: { $set: postsMap },
          error: { $set: null },
          isLoading: { $set: false },
          filteredPosts: { $remove: removedPostUids },
        }),
      );
    });
  }

  /**
   * Handles the newly filtered uids from the `FilterInput`.
   */
  handleFilteredData = uids => {
    this.setState({ filteredPosts: new Set(uids) });
  };

  renderPortalSearchBar() {
    const { mountNode } = this.props;

    const posts = this.getPosts(this.state);

    return ReactDOM.createPortal(
      <FilterInput
        data={posts}
        fields={['activity', 'caption']}
        onFilter={this.handleFilteredData}
        placeholder="Search Lifts (activity, caption)"
      />,
      mountNode,
    );
  }

  render() {
    const { isLoading } = this.state;

    const filteredPosts = this.getFilteredPosts(this.state);

    return (
      <Fragment>
        {this.renderPortalSearchBar()}
        <Loader active={isLoading} inline="centered" size="huge" />
        {!isLoading && (
          <LiftTable
            posts={filteredPosts}
            onRemove={() => this.refreshPosts()}
          />
        )}
      </Fragment>
    );
  }
}

export default AbusePage;
