import axios from 'axios';
import update from 'immutability-helper';
import { firebaseAuth } from './firebase';

import { customApi } from '../config';

export const API_ROOT_URL = `${customApi.apiUrl}${customApi.apiVersion}/`;

export const initApiClient = function initApiClient() {
  axios.defaults.baseURL = API_ROOT_URL;
};

/**
 * Adds the authorization token to a config object.
 * Use this function before each API call to be authorised.
 * @param { object } [ config = {} ] A base config object to modify. If none is
 * specified, the function will return a config object containing the authorization token.
 * @returns { Promise<object> } A new config object, based on the provided parameter, containing
 * the authorization token.
 */
export const getTokenedConfig = async function getTokenedConfig(config = {}) {
  let token;

  if (firebaseAuth().currentUser) {
    token = await firebaseAuth().currentUser.getIdToken();
  }

  const bearerString = token && `Bearer ${token}`;
  const tokenedConfig = bearerString
    ? update(config, {
        headers: headers =>
          update(headers || {}, {
            Authorization: { $set: bearerString },
          }),
      })
    : config;
  return tokenedConfig;
};

export const getApiEndpoint = {
  authentication: {
    login() {
      return 'admin/login';
    },
  },

  users: {
    suspend(uid) {
      return `users/${uid}/suspend`;
    },
    unsuspend(uid) {
      return `users/${uid}/unsuspend`;
    },
    certifyTrainer(uid) {
      return `users/${uid}/approve-coach`;
    },
    decertifyTrainer(uid) {
      return `users/${uid}/disapprove-coach`;
    },
    verifyProfile(uid) {
      return `users/${uid}/verify-profile`;
    },
    unverifyProfile(uid) {
      return `users/${uid}/unverify-profile`;
    },
  },

  posts: {
    verifyLift(uid) {
      return `posts/${uid}/verify`;
    },
    ignorePostReport(uid) {
      return `posts/${uid}/clear-report`;
    },
  },
};
