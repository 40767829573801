import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Label, Loader, Header } from 'semantic-ui-react';

const NotificationLine = ({ notifications, message, link, loading }) => (
  <Segment className="notification-line" basic>
    <Label
      circular
      size="big"
      className="number"
      color={!loading && notifications === 0 ? 'green' : 'red'}
    >
      <Loader inline="centered" active={loading} inverted size="tiny" />
      {!loading && notifications}
    </Label>
    <Header className="title" size="small">
      {message}
    </Header>
    <Link to={link} className="ui button secondary -block">
      Review
    </Link>
  </Segment>
);

export default NotificationLine;
