import React from 'react';
import { LoggedInUserContext } from './higher-order/withLoggedInUser';
import { Loader } from 'semantic-ui-react';

import { convertCmToFeetAndInches } from '../modules/units';

/**
 * Converts the given weight value into the user's preferred unit.
 * @param { object } currentUser The current user from the context.
 * @param { number|string } value The value to convert.
 * @param { boolean } displayUnit true to include the unit in the resulting string.
 * @returns { string|number } The converted value into the user's preferred unit,
 * number if `displayUnit` is false, else a string with the value and the unit
 * (e.g. `164 lbs`).
 */
export const convertHeighIntoUserPreferredUnit = function convertHeighIntoUserPreferredUnit(
  { profile },
  value,
  displayUnit,
) {
  const { units: { height = 'ft' } = {} } = profile;

  let convertedValue;

  switch (height) {
    case 'ft': {
      const { feet = 0, inches = 0 } = convertCmToFeetAndInches(value) || {};
      convertedValue = displayUnit ? `${feet}'${inches}"` : `${feet} ${inches}`;
      break;
    }

    case 'cm': {
      const roundedValue = Math.round(value);
      convertedValue = displayUnit ? `${roundedValue} cm` : roundedValue;
      break;
    }

    default: {
      console.warn(`<Weight />: unrecognised unit '${height}'`);
      convertedValue = value;
      break;
    }
  }

  return convertedValue;
};

const Height = function Height({
  children,
  value = children,
  displayUnit = true,
}) {
  return (
    <LoggedInUserContext.Consumer>
      {userCtx => {
        if (!userCtx.profile) {
          return <Loader inline size="mini" />;
        }
        return convertHeighIntoUserPreferredUnit(userCtx, value, displayUnit);
      }}
    </LoggedInUserContext.Consumer>
  );
};

export default Height;
