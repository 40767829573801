/**
 * @module modules/errors
 * Module dedicated to error management.
 */

let loggingFunction;

switch (process.env.REACT_APP_FLEXPIT_ENV) {
  case 'development': {
    loggingFunction = console.error;
    break;
  }

  case 'production':
  default: {
    loggingFunction = () => {};
    break;
  }
}

export const logError = function logError(error) {
  loggingFunction(error);
  throw error;
};

export const ERROR_MESSAGES = {
  notAnAdmin: 'You need to be an admin to access the dashboard!',
};
