import axios from 'axios';
import check from 'check-types';
import { db } from '../modules/firebase';
import { getApiEndpoint, getTokenedConfig } from '../modules/api';
import { logError } from '../modules/errors';
import { checkArguments } from '../modules/services';
import { parseData } from './utils';

const parseUser = function parseUser({ dateOfBirth, ...user }) {
  return {
    ...parseData(user),
    dateOfBirth: dateOfBirth && dateOfBirth.toDate(),
  };
};

/*
 * All Users
 */
export const getUsers = function getUsers() {
  try {
    const users = db
      .collection('users')
      .get()
      .then(function(querySnapshot) {
        const data = [];
        querySnapshot.forEach(function(doc) {
          data.push(parseUser(doc.data()));
        });
        return data;
      });
    return users;
  } catch (e) {
    console.error(e, 'users.js > getUsers');
  }
};

/**
 * Approves a trainer's certification request.
 * @param {Object} params
 * @param {string} params.uid - the user to certify's uid
 * @param {string} [params.feedback] - feedback to send with the approval
 * @returns {Promise}
 */
export const certifyTrainer = async function certifyTrainer(params) {
  if (
    !check.all(
      check.map(params, {
        uid: check.nonEmptyString,
        feedback: check.maybe.string,
      }),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = params;
    throw error;
  }

  const { uid, feedback } = params;

  const apiParams = {
    feedback,
  };
  const config = await getTokenedConfig();

  return axios
    .post(getApiEndpoint.users.certifyTrainer(uid), apiParams, config)
    .catch(err => console.error(err, 'users.js > certifyTrainer'));
};

/**
 * Decertifies or decline a user's certification request.
 * @param {Object} params
 * @param {string} params.uid - the user to decertify's uid
 * @param {string} [params.feedback] - the reason for decertifying the user
 * @returns {Promise}
 */
export const decertifyTrainer = async function decertifyTrainer(params) {
  // Validate params
  if (
    !check.all(
      check.map(params, {
        uid: check.nonEmptyString,
        feedback: check.maybe.string,
      }),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = params;
    throw error;
  }

  const { uid, feedback } = params;

  const apiParams = {
    feedback,
  };
  const config = await getTokenedConfig();

  return axios
    .post(getApiEndpoint.users.decertifyTrainer(uid), apiParams, config)
    .catch(error => console.error(error, 'users.js > decertifyTrainer'));
};

/**
 * Approves the user's verify profile request. a user's verification request.
 * @param {Object} params
 * @param {string} params.uid - the user to unverify's id
 * @param {string} [params.feedback] - the reason for unverifying the user
 * @returns {Promise}
 */
export const verifyProfile = async function verifyProfile(params) {
  if (
    !check.all(
      check.map(params, {
        uid: check.nonEmptyString,
        feedback: check.maybe.string,
      }),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = params;
    throw error;
  }

  const { uid, feedback } = params;
  const url = getApiEndpoint.users.verifyProfile(uid);
  const apiParams = {
    feedback,
  };

  let result;
  try {
    const config = await getTokenedConfig();
    result = await axios.post(url, apiParams, config);
  } catch (error) {
    logError(error);
  }

  return result;
};

/**
 * Unverifies a user or decline
 * @param { object } params
 * @param { string } params.uid The user to verify's id
 * @param { string } [params.feedback] The feedback to send with the approval.
 * @param { boolean } [ parans.removeReferal ] true to remove the reference object
 * in the user (mainly for Scouts).
 * @returns { Promise }
 */
export const unverifyProfile = function unverifyProfile(params) {
  // Validate params
  if (
    !check.all(
      check.map(params, {
        uid: check.nonEmptyString,
        feedback: check.maybe.string,
        removeReferal: check.maybe.boolean,
      }),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = params;
    throw error;
  }

  const { uid, feedback, removeReferal = false } = params;

  // If asked to remove the reference (scouts)
  let userRef;
  if (removeReferal) {
    const users = db.collection('users');
    userRef = users.doc(uid);
  }

  const url = getApiEndpoint.users.unverifyProfile(uid);
  const apiParams = {
    feedback,
  };

  const apiPromise = getTokenedConfig().then(config =>
    axios.post(url, apiParams, config),
  );
  const firebasePromise = userRef && userRef.update({ reference: null });

  return Promise.all([apiPromise, firebasePromise])
    .then(([response]) => response)
    .catch(logError);
};

export const getUser = function getUser(userUid) {
  const users = db.collection('users');

  const promise = users
    .doc(userUid)
    .get()
    .then(function(doc) {
      if (doc.exists) {
        const data = parseUser(doc.data());
        return data;
      } else {
        return null;
      }
    })
    .catch(function(error) {
      console.error(error, 'users.js > getUser');
    });

  return promise;
};

/**
 * Marks or unmarks a user as admin.
 * @param {Object} user - the user data
 * @param {string} user.uid - the user to update's id
 * @param {boolean} [user.isAdmin=false] - should the user be set as an admin or not
 * @returns {Promise}
 */
export const setUserAdmin = function setUserAdmin({ uid, isAdmin = false }) {
  if (!uid) {
    console.error('No uid provided');
    return;
  }

  const user = db.collection('users').doc(uid);
  return user.update({ isAdmin }).catch(error => {
    console.error(error, 'users.js > setUserAdmin');
  });
};

/**
 * Suspends or unsunspends a user.
 * @param {Object} user - the user data
 * @param {string} user.uid - the user to update's id
 * @param {boolean} user.suspend - should the user be suspended or not
 * @returns {Promise}
 */
export const setUserSuspended = async function setUserSuspended({
  uid,
  suspend,
}) {
  if (!uid || suspend === undefined) {
    const error = new Error('Internal error: malformed arguments');
    error.params = { uid, suspend };
    console.error(error);
    throw error;
  }

  const action = suspend ? 'suspend' : 'unsuspend';
  const url = getApiEndpoint.users[action](uid);

  let result;

  try {
    const config = await getTokenedConfig();
    result = await axios.post(url, {}, config);
  } catch (error) {
    logError(error);
  }

  return result;
};

/**
 * Gets trainers that requested to be certified and are waiting their certification
 * to be approved or disapproved.
 */
export const getPendingCertificationTrainers = async function getPendingCertificationTrainers() {
  try {
    const trainers = db
      .collection('users')
      .where('userType', '==', 'Trainer')
      .where('approved', '==', false)
      .get()
      .then(querySnapshot =>
        querySnapshot.docs
          .map(doc => parseUser(doc.data()))
          .filter(
            ({ documentation }) => documentation && documentation.length > 0,
          ),
      );
    return trainers;
  } catch (e) {
    console.error(e, 'users.js > getPendingCertificationTrainers');
  }
};

/**
 * Gets users that requested their profile to be verified and are waiting for approval.
 */
export const getPendingVerificationUsers = function getPendingVerificationUsers() {
  try {
    const unverifiedProfiles = db
      .collection('users')
      .where('verified', '==', false)
      .get()
      .then(querySnapshot =>
        querySnapshot.docs
          .map(doc => doc.data())
          .filter(
            ({ verificationDocuments }) =>
              verificationDocuments && verificationDocuments.length > 0,
          ),
      );
    const unverifiedScouts = db
      .collection('users')
      .where('userType', '==', 'Scout')
      .where('verified', '==', false)
      .get()
      .then(querySnapshot =>
        querySnapshot.docs
          .map(doc => parseUser(doc.data()))
          .filter(user => user.reference),
      );

    return Promise.all([unverifiedProfiles, unverifiedScouts]).then(([a, b]) =>
      a.concat(b),
    );
  } catch (e) {
    console.error(e, 'users.js > getAhtleteNotification');
  }
};

/**
 * Removes the specified verification document references from the specified user.
 * @param {Object} user
 * @param {string} user.uid - the uid of the user to update
 * @param {string[]} user.documents - the paths of the documents to remove
 * @returns {Promise}
 */
export const removeVerificationDocuments = function removeVerificationDocuments({
  uid,
  documents,
}) {
  if (
    !check.all(
      check.map(
        { uid, documents },
        {
          uid: check.nonEmptyString,
          documents: check.array.of.nonEmptyString,
        },
      ),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = { uid, documents };
    console.error(error);
    throw error;
  }

  const userRef = db.collection('users').doc(uid);

  return db
    .runTransaction(transaction => {
      return transaction.get(userRef).then(user => {
        if (!user.exists) {
          throw new Error('User does not exist.');
        }

        const { verificationDocuments } = user.data();
        const newDocuments = verificationDocuments.filter(
          doc => documents.indexOf(doc) === -1,
        );

        return transaction.update(userRef, {
          verificationDocuments: newDocuments.length > 0 ? newDocuments : null,
        });
      });
    })
    .catch(error => {
      console.error(error, 'users.js > removeVerificationDocuments');
    });
};

/**
 * Removes the specified certification document references from the specified user.
 * @param {Object} user
 * @param {string} user.uid - the uid of the user to update
 * @param {string[]} documents - the paths of the documents to remove
 * @returns {Promise}
 */
export const removeDocumentation = function removeDocumentation({
  uid,
  documents,
}) {
  if (
    !check.all(
      check.map(
        { uid, documents },
        {
          uid: check.nonEmptyString,
          documents: check.array.of.nonEmptyString,
        },
      ),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = { uid, documents };
    console.error(error);
    throw error;
  }

  const userRef = db.collection('users').doc(uid);

  return db
    .runTransaction(transaction => {
      return transaction.get(userRef).then(user => {
        if (!user.exists) {
          throw new Error('User does not exist.');
        }

        const { documentation } = user.data();
        const newDocuments = documentation.filter(
          doc => documents.indexOf(doc) === -1,
        );

        return transaction.update(userRef, {
          documentation: newDocuments.length > 0 ? newDocuments : null,
        });
      });
    })
    .catch(error => {
      console.error(error, 'users.js > removeDocumentation');
    });
};

/**
 * Updates the specified user.
 * @param { object } user The data to update the user with,
 * @param { string } user.uid The uid of the user to update.
 * @returns { Promise } A Promise that resolves when the user is updated.
 */
export const updateUser = function updateUser(user) {
  checkArguments(user, {
    uid: check.nonEmptyString,
  });

  const { uid, ...data } = user;

  const userRef = db.collection('users').doc(uid);

  return userRef.update(data).catch(logError);
};
