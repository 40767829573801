import React from 'react';
import { string, bool, node } from 'prop-types';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import classNames from 'classnames';

const propTypes = {
  to: string,
  replace: bool,
  children: node,
  className: string,
};

const defaultProps = {
  to: '',
  replace: undefined,
  children: undefined,
};

const TableCellLink = function TableCellLink({
  to,
  children,
  replace,
  className,
  ...tdProps
}) {
  return (
    <Table.Cell className={classNames('-link', className)} {...tdProps}>
      <Link to={to} replace={replace} className="link -semantic block">
        {children}
      </Link>
    </Table.Cell>
  );
};

TableCellLink.propTypes = propTypes;
TableCellLink.defaultProps = defaultProps;

export default TableCellLink;
