import React, { Component, Fragment } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { Card, Loader, Message, Modal } from 'semantic-ui-react';

import ScrollingModal from '../ScrollingModal';
import PostItem from './PostItem';

const propTypes = {
  posts: arrayOf(
    shape({
      uid: string.isRequired,
      mediaUrl: string.isRequired,
      mediaType: string.isRequired,
      thumbnailUrl: string,
    }),
  ).isRequired,
  isLoading: bool,
  error: string,
};

const defaultProps = {
  isLoading: false,
  error: undefined,
};

class PostGrid extends Component {
  state = {
    isModalOpen: false,
    selectedPostUid: '',
  };

  componentWillReceiveProps(nextProps) {
    const { posts } = this.props;
    const { selectedPostUid } = this.state;

    // If the post opened in the modal does not exist anymore in props, close the modal
    if (
      selectedPostUid &&
      posts !== nextProps.props &&
      !nextProps.posts.find(post => post.uid === selectedPostUid)
    ) {
      this.setState({ selectedPostUid: '', isModalOpen: false });
    }
  }

  openModal = selectedPostUid =>
    this.setState({ selectedPostUid, isModalOpen: true });

  closeModal = () => this.setState({ isModalOpen: false });

  renderMessage() {
    const { error } = this.props;

    return !error ? (
      <p className="nodatamessage">No post to display.</p>
    ) : (
      <Message error={Boolean(error)} compact>
        <p>{error}</p>
      </Message>
    );
  }

  render() {
    const { posts, isLoading } = this.props;

    const { isModalOpen, selectedPostUid } = this.state;

    const selectedPost =
      selectedPostUid && posts.find(({ uid }) => uid === selectedPostUid);

    // Prepare props for posts to render as cards
    const postsNodes = posts.map(({ uid, mediaType, mediaUrl, thumbnail }) => {
      const image = thumbnail ? thumbnail.medium.url : mediaUrl;

      const style = {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      };

      return (
        <Card
          key={uid}
          className="post"
          style={style}
          onClick={() => this.openModal(uid)}
        />
      );
    });

    return (
      <div className="post-grid">
        {!isLoading && postsNodes.length === 0 ? (
          this.renderMessage()
        ) : (
          <Fragment>
            <div className="post-grid">{postsNodes}</div>
            <Loader active={isLoading} inline="centered" />
          </Fragment>
        )}

        <ScrollingModal
          open={isModalOpen}
          onClose={this.closeModal}
          size="tiny"
          className="-notpadded"
        >
          <Modal.Content>
            <PostItem post={selectedPost} />
          </Modal.Content>
        </ScrollingModal>
      </div>
    );
  }
}

PostGrid.propTypes = propTypes;
PostGrid.defaultProps = defaultProps;

export default PostGrid;
