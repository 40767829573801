/**
 * Inject params into a template route.
 *
 * @param {String} template - Template route where to replace params (see routes constant below)
 * @param {Object} params - object containing the params to inject in the route,
 * with the parameter's name as a key, and the value to inject as a value.
 *
 * @returns {String} A new string with the injected params. Unspecified param in
 * the `params` object will be replaced with an empty string for optional ones,
 * and will not be replaced for other ones (this allows chaining).
 */
export const injectParamsIntoRoute = function injectParamsIntoRoute(
  template,
  params = {},
) {
  if (!template) {
    throw new Error('Use of makeRoute without a template route');
  }

  const routeWithParams = template.replace(
    /\/:([\w]+)(\?)?/g, // Checks for :param or optional :param?
    (match, name, isOptional) => {
      if (isOptional) {
        const value = params[name];
        return value ? `/${value}` : '/'; // Unprovided optional params are replaced with an empty string
      }

      // Unprovided params are not replaced, this allows chaining of calls.
      return `/${params[name] || match}`;
    },
  );

  return routeWithParams;
};

export const routes = {
  users: {
    root: '/users',
    details: '/users/:userId',
  },
  notifications: '/notifications/:filter?',
};
