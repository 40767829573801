import React from 'react';
import { shape, string, bool, instanceOf, oneOfType, number } from 'prop-types';
import { Input, Grid, Form, TextArea, Loader } from 'semantic-ui-react';
import moment from 'moment';

import { LoggedInUserContext } from '../higher-order/withLoggedInUser';
import { convertWeightIntoUserPreferredUnit } from '../Weight';
import { convertHeighIntoUserPreferredUnit } from '../Height';

const propTypes = {
  details: shape({
    bio: string,
    dateOfBirth: instanceOf(Date),
    weight: oneOfType([string, number]),
    height: oneOfType([string, number]),
    plays: string,
    team: string,
    sex: string,
  }),
  readOnly: bool,
};

const defaultProps = {
  details: {},
  readOnly: true,
};

const sexLabels = {
  M: 'Male',
  F: 'Female',
};

const UserDetails = function UserDetails({
  details: {
    bio = '',
    dateOfBirth = '',
    weight = '',
    height = '',
    sport = '',
    team = '',
    sex = '',
  },
  readOnly,
  ...otherProps
}) {
  const momentedDateOfBirth = dateOfBirth && moment(dateOfBirth);

  return (
    <Grid columns="equal" stackable as={Form} {...otherProps}>
      <Grid.Column as={Form.Group}>
        <Form.Field>
          <label htmlFor="user-details-form-bio">Bio</label>
          <TextArea
            id="user-details-form-bio"
            name="bio"
            value={bio}
            readOnly={readOnly}
            rows={5}
          />
        </Form.Field>
      </Grid.Column>

      <Grid.Column>
        <Form.Group widths="equal">
          <Form.Input
            control="input"
            id="user-details-form-dateOfBirth"
            label="Date of birth"
            name="dateOfBirth"
            value={
              momentedDateOfBirth && momentedDateOfBirth.format('DD/MM/YYYY')
            }
            readOnly={readOnly}
          />
          <Form.Field>
            <label htmlFor="user-details-form-weight">Weight</label>
            <LoggedInUserContext.Consumer>
              {userCtx => {
                if (!userCtx.profile) {
                  return <Loader inline size="mini" />;
                }
                const [value, unit] = convertWeightIntoUserPreferredUnit(
                  userCtx,
                  weight,
                  true,
                ).split(' ');

                return (
                  <Input
                    fluid
                    id="user-details-form-weight"
                    label={{ basic: true, content: unit }}
                    labelPosition="right"
                    name="weight"
                    value={value}
                    readOnly={readOnly}
                  />
                );
              }}
            </LoggedInUserContext.Consumer>
          </Form.Field>
          <Form.Field>
            <label htmlFor="user-details-form-height">Height</label>
            <LoggedInUserContext.Consumer>
              {userCtx => {
                if (!userCtx.profile) {
                  return <Loader inline size="mini" />;
                }
                const value = convertHeighIntoUserPreferredUnit(
                  userCtx,
                  height,
                  true,
                );
                return (
                  <Input
                    fluid
                    id="user-details-form-height"
                    name="height"
                    value={value}
                    readOnly={readOnly}
                  />
                );
              }}
            </LoggedInUserContext.Consumer>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Input
            control="input"
            id="user-details-form-sport"
            label="Plays"
            name="sport"
            value={sport}
            readOnly={readOnly}
          />
          <Form.Input
            control="input"
            id="user-details-form-team"
            label="For"
            name="team"
            value={team}
            readOnly={readOnly}
          />
          <Form.Input
            control="input"
            id="user-details-form-sex"
            label="Sex"
            name="sex"
            value={sex && sexLabels[sex]}
            readOnly={readOnly}
          />
        </Form.Group>
      </Grid.Column>
    </Grid>
  );
};

UserDetails.propTypes = propTypes;
UserDetails.defaultProps = defaultProps;

export default UserDetails;
