import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

const makeInputProps = function makeInputProps(
  { id, onChange, formData, data },
  name,
) {
  return {
    onChange,
    id: `${id}-${name}`,
    value: formData[name] || data[name],
  };
};

class ReferenceForm extends Component {
  static defaultProps = {
    id: 'reference-form',
    data: {},
    formData: {},
    inputProps: {},
  };

  render() {
    const { inputProps, data, formData, disabled, ...props } = this.props;
    return (
      <Form {...props}>
        <Form.Input
          {...inputProps}
          {...makeInputProps(this.props, 'name')}
          label="Referee name"
        />
        <Form.Input
          {...inputProps}
          {...makeInputProps(this.props, 'email')}
          label="Referee email"
        />
        <Form.TextArea
          {...inputProps}
          {...makeInputProps(this.props, 'relationship')}
          label="Referee relationship"
        />
      </Form>
    );
  }
}

export default ReferenceForm;
