import React, { Component } from 'react';
import { string, bool, func, object } from 'prop-types';
import { Button, Form, Message } from 'semantic-ui-react';

export default class VerifyLiftForm extends Component {
  static propTypes = {
    disabled: bool,
    feedback: string,
    isApproved: bool,
    id: string,
    onChange: func,
    message: object,
  };

  static defaultProps = {
    disabled: false,
    isApproved: undefined,
    feedback: '',
    id: 'verify-lift-form',
    onChange: undefined,
    message: undefined,
  };

  updateApprovedAndSubmit(value) {
    const { onSubmit, onChange } = this.props;
    onChange({ target: { value, name: 'isApproved' } }, onSubmit);
  }

  submitDecline = () => this.updateApprovedAndSubmit(false);
  submitApprove = () => this.updateApprovedAndSubmit(true);

  render() {
    const {
      disabled,
      feedback,
      isApproved,
      id,
      onChange,
      loading,
      message,
      onSubmit,
      ...props
    } = this.props;

    return (
      <Form id={id} success error onSubmit={this.submitApprove} {...props}>
        <Form.Group>
          <Form.Input
            autoFocus
            id={`${id}-feedback`}
            className="-fluid"
            control="input"
            name="feedback"
            placeholder="Feedback"
            onChange={onChange}
            value={feedback}
            disabled={disabled}
          />
          {onSubmit && (
            <Form.Button
              type="submit"
              circular
              primary
              fluid
              disabled={disabled}
              loading={loading}
            >
              Approve lift
            </Form.Button>
          )}
          {onSubmit && (
            <Form.Field>
              <Button
                fluid
                negative
                className="-tertiary"
                disabled={disabled}
                loading={loading}
                onClick={this.submitDecline}
              >
                Disapprove lift
              </Button>
            </Form.Field>
          )}
        </Form.Group>
        {message && <Message {...message} />}
      </Form>
    );
  }
}
