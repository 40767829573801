import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';

import UsersTable from './UsersTable';
import Filters from './Filters';
import withLoggedInUser from '../higher-order/withLoggedInUser';
import { convertWeightIntoUserPreferredUnit } from '../Weight';
import { convertHeighIntoUserPreferredUnit } from '../Height';

const Users = ({
  filters,
  users,
  sportOptions,
  lvlOptions,
  onChangeFilter,
  requestRefreshData,
  currentUserState,
}) => {
  const csvData = [
    [
      'firstName',
      'lastName',
      'email',
      'username',
      'height',
      'weight',
      'locationName',
      'sex',
      'sport',
      'sportLevel',
      'userType',
    ],
  ];

  users.forEach(user => {
    const array = [
      user.firstName,
      user.lastName,
      user.email,
      user.username,
      currentUserState.profile &&
        convertHeighIntoUserPreferredUnit(currentUserState, user.height, true),
      currentUserState.profile &&
        convertWeightIntoUserPreferredUnit(currentUserState, user.weight, true),
      user.locationName,
      user.sex,
      user.sport,
      user.sportLevel,
      user.userType,
    ];

    csvData.push(array);
  });
  return (
    <Fragment>
      <div className="padded">
        <Header as="h2">
          Users
          <CSVLink
            data={csvData}
            filename="users.csv"
            className="ui primary compact button circular"
            target="_blank"
          >
            Export user data
          </CSVLink>
        </Header>
        <Filters
          onChange={onChangeFilter}
          sportOptions={sportOptions}
          lvlOptions={lvlOptions}
          values={filters}
        />
      </div>
      <UsersTable users={users} requestRefreshData={requestRefreshData} />
    </Fragment>
  );
};

export default withLoggedInUser(Users);
