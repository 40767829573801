import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import update from 'immutability-helper';

import './styles/App.scss';

import { firebaseAuth } from './modules/firebase';
import App from './App';
import LoginPage from './pages/LoginPage';
import Loading from './components/Loading';
import ThemingPage from './pages/ThemingPage';
import {
  checkIsAdmin,
  logout,
  getLoggedInUid,
} from './services/authentication';
import { ERROR_MESSAGES } from './modules/errors';
import { LoggedInUserContext } from './components/higher-order/withLoggedInUser';
import { getUser } from './services/users';

class Auth extends Component {
  state = {
    /**
     * We have three type of logging in events:
     * * `check` is the first check when opening the app of a session-logged user;
     * * `email` the first step of manual logging in, which does not really logs in;
     * * `custom-token` the second of manual logging in, which is the actual log in.
     */
    loggingType: 'check',
    /**
     * At the beginning, Firebase checks if there is a user already
     * authenticated in session. Until this is done, the status
     * of loggedIn is undefined.
     */
    isLoggedIn: undefined,
    error: undefined,
    currentUser: LoggedInUserContext.initialState,
  };

  componentDidMount() {
    firebaseAuth().onAuthStateChanged(async authUser => {
      const { loggingType } = this.state;
      let newState;

      switch (loggingType) {
        case 'check': {
          if (authUser) {
            const isAdmin = await checkIsAdmin();
            if (!isAdmin) {
              this.setState({
                error: new Error(ERROR_MESSAGES.notAnAdmin),
                isLoggedIn: false,
              });
              return logout();
            }

            // Fetch user's data
            this.refreshCurrentUserProfile();
          }
          newState = { isLoggedIn: Boolean(authUser) };

          break;
        }

        case 'custom-token': {
          if (authUser) {
            this.refreshCurrentUserProfile();
          }
          newState = { isLoggedIn: Boolean(authUser) };
          break;
        }

        // Ignore the `email` type sign-in
        case 'email':
        default: {
          break;
        }
      }

      if (newState) {
        this.setState({
          isLoggedIn: !!authUser,
        });
      }
    });
  }

  /**
   * Requests to refresh the user using the API, or to update the data manually.
   * @param { object } [updateQuery] The update query for the profile. If none is given,
   * the function will call the API.
   */
  async refreshCurrentUserProfile(updateQuery) {
    let error = null;
    let profile = updateQuery;
    let lastUpdatedAt = {};

    if (!profile) {
      try {
        const data = await getUser(getLoggedInUid());
        profile = { $set: data };
        lastUpdatedAt = { lastUpdatedAt: { $set: Date.now() } };
      } catch (internalError) {
        error = internalError;
      }
    }

    this.setState(
      update(this.state, {
        currentUser: {
          profile,
          isFetching: { $set: false },
          error: { $set: error },
          ...lastUpdatedAt,
        },
      }),
    );
  }

  getPromiseOfStateUpdate = loggingType => {
    return new Promise((resolve, reject) => {
      this.setState({ loggingType }, resolve);
    });
  };

  handleRequestRefreshCurrentUser = (...args) => {
    const { isLoggedIn } = this.state;
    return isLoggedIn
      ? this.refreshCurrentUserProfile(...args)
      : Promise.resolve(null);
  };

  render() {
    const { currentUser, isLoggedIn } = this.state;

    const currentUserState = {
      ...currentUser,
      refreshCurrentUser: this.handleRequestRefreshCurrentUser,
    };

    // Display a loader until the current status of logged in is known
    if (isLoggedIn === undefined) {
      return <Loading />;
    }

    return (
      <LoggedInUserContext.Provider value={currentUserState}>
        <BrowserRouter>
          <Switch>
            <Route path="/test-theme" component={ThemingPage} />
            <Route
              exact
              path="/"
              render={() => (
                // Default route redirects to /overview if logged, else /login
                <Redirect to={isLoggedIn ? '/overview' : '/login'} />
              )}
            />
            <Route
              path="/login"
              render={() =>
                // If already logged in, redirect to default route, else display login page
                !isLoggedIn ? (
                  <LoginPage noticeLoggingType={this.getPromiseOfStateUpdate} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/"
              render={() =>
                // If not logged in, redirect to /login, else explore other app routes
                isLoggedIn ? <App /> : <Redirect to="/login" />
              }
            />
          </Switch>
        </BrowserRouter>
      </LoggedInUserContext.Provider>
    );
  }
}

export default Auth;
