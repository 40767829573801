import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react';

import { getOverviewStats } from '../services/stats';
import StatsCards from '../components/overview/StatsCards';
import HeaderBar from '../components/navigation/HeaderBar';
import Notifications from '../components/overview/Notifications';

class OverviewPage extends Component {
  state = {
    isLoading: true,
    stats: {
      totalUsers: undefined,
      totalPosts: undefined,
      totalTrainers: undefined,
      totalAthletes: undefined,
    },
  };

  componentDidMount() {
    getOverviewStats().then(data => {
      this.setState({ stats: data, isLoading: false });
    });
  }

  render() {
    const { stats, isLoading } = this.state;
    return [
      <HeaderBar key="header" as="header" />,
      <Container key="main-content" as="main" className="main" fluid>
        <div className="padded">
          <Header as="h2">Overview</Header>
          <StatsCards stats={stats} loading={isLoading} />
          <Header as="h2">Notifications</Header>
          <Notifications />
        </div>
      </Container>,
    ];
  }
}

export default OverviewPage;
