export const userTypes = {
  athlete: 'Athlete',
  trainer: 'Trainer',
};

const { athlete, trainer } = userTypes;

export const permissions = {
  beVerified: [athlete, trainer],
};

/**
 * Check if a user is authorised to do one of the permissions defined above.
 * @param { Object|String } user - the user to check (can be a user with a userType key), or a string
 * @param { [String] } permission - the permission to check.
 * @return undefined if one of the parameter is undefined, true if the user is allowed, false if they are not.
 */
export const can = function can(user, permission) {
  if (!user || !permission) return undefined;

  const { userType = user } = user;
  return permission.indexOf(userType) !== -1;
};
