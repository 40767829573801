import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dimmer,
  Form,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';
import classNames from 'classnames';
import update from 'immutability-helper';

import DocumentTable from '../documents/DocumentTable';
import { getFileData, removeFile } from '../../services/files';
import {
  certifyTrainer,
  removeDocumentation,
  decertifyTrainer,
} from '../../services/users';

const jobMessages = {
  approve: 'Approving certification',
  decline: 'Rejecting certification',
};

export default class CertifyTrainerModal extends Component {
  static propTypes = {
    userUid: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    documentation: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFinishCertification: PropTypes.func,
  };

  state = {
    files: {
      error: undefined,
      data: null,
      waitingApi: true,
    },
    certification: {
      error: undefined,
      waitingApi: '',
    },
    form: {
      feedback: '',
    },
  };

  componentDidMount() {
    this.getFileData();
  }

  async sendCertificationRequest(approved) {
    const { close, userUid, onFinishCertification } = this.props;
    const { form: { feedback } } = this.state;

    // TODO FLEX-292 Test purpose, change with actual API call when implemented
    let apiCall = decertifyTrainer;
    let codeCall = 'decline';

    if (approved) {
      apiCall = certifyTrainer;
      codeCall = 'approve';
    }

    try {
      this.setState(
        update(this.state, {
          certification: {
            error: { $set: undefined },
            waitingApi: { $set: codeCall },
          },
        }),
      );

      const params = {
        feedback,
        uid: userUid,
      };

      await apiCall(params);

      this.setState(
        update(this.state, {
          certification: {
            error: { $set: null },
            waitingApi: { $set: '' },
          },
        }),
      );

      close();
      if (onFinishCertification) onFinishCertification(approved);
    } catch (error) {
      this.setState(
        update(this.state, {
          certification: {
            error: { $set: error },
            waitingApi: { $set: '' },
          },
        }),
      );
    }
  }

  async removeDocument(fileToDelete) {
    const { userUid } = this.props;
    const { files: { data } } = this.state;

    // Show loading state, and remove any error
    this.setState(
      update(this.state, {
        files: {
          error: { $set: undefined },
          waitingApi: { $set: 'deleteFile' },
        },
      }),
    );

    try {
      // Remove file from storage
      await removeFile(fileToDelete);

      // Remove file reference from user
      await removeDocumentation({
        uid: userUid,
        documents: [`/${fileToDelete}`],
      });

      // Remove file in state to hide it without refreshing, and finalise user interface feedback
      const newFiles = data.filter(file => file.fullpath === fileToDelete);
      this.setState(
        update(this.state, {
          files: {
            error: { $set: null },
            data: { $set: newFiles },
            waitingApi: { $set: '' },
          },
        }),
      );
    } catch (error) {
      this.setState(
        update(this.state, {
          files: {
            error: { $set: error },
            waitingApi: { $set: '' },
          },
        }),
      );
    }
  }

  getFileData = () => {
    const { documentation } = this.props;
    const { files } = this.state;

    if (!files.waitingApi) {
      this.setState(
        update(this.state, { files: { waitingApi: { $set: true } } }),
      );
    }

    return Promise.all(documentation.map(getFileData))
      .then(fileData => {
        this.setState(
          update(this.state, {
            files: {
              error: { $set: null },
              data: { $set: fileData },
              waitingApi: { $set: false },
            },
          }),
        );
      })
      .catch(internalError => {
        const error = new Error(
          `Internal error, could not read document (${internalError.message})`,
        );
        error.internalError = internalError;

        this.setState(
          update(this.state, {
            files: {
              error: { $set: error },
              waitingApi: { $set: false },
            },
          }),
        );
      });
  };

  declineCertification = () => this.sendCertificationRequest(false);
  completeCertification = () => this.sendCertificationRequest(true);

  requestRemoveDocument = ({ target: { value } }) => this.removeDocument(value);

  handleFormChange = ({ target: { name, value } }) =>
    this.setState(
      update(this.state, {
        form: { [name]: { $set: value } },
      }),
    );

  render() {
    const {
      className,
      close,
      userUid,
      documentation,
      onFinishCertification,
      ...modalProps
    } = this.props;

    const {
      files: { error: fileError, data: filesData, waitingApi: filesLoading },
      certification: { error = fileError, waitingApi: certificationLoading },
      form: { feedback },
    } = this.state;

    return (
      <Modal
        className={classNames(
          filesData && filesData.length > 0 && '-notpadded',
          className,
        )}
        onClose={!certificationLoading ? close : undefined}
        {...modalProps}
      >
        <Modal.Header>Certify a trainer</Modal.Header>

        <Modal.Content>
          <Dimmer active={Boolean(certificationLoading)} inverted>
            <Loader size="medium">
              {jobMessages[certificationLoading]}...
            </Loader>
          </Dimmer>

          <DocumentTable
            isLoading={Boolean(filesLoading)}
            files={filesData}
            onRemove={this.requestRemoveDocument}
          />

          <Form className="forcepadding">
            <Form.Input
              autoFocus
              fluid
              label="Feedback"
              name="feedback"
              placeholder="e.g. Missing document, unreadable part..."
              onChange={this.handleFormChange}
              value={feedback}
            />
          </Form>

          {error && <Message negative>{error.reason || error.message}</Message>}
        </Modal.Content>

        <Modal.Actions>
          <Button
            circular
            className="-tertiary"
            onClick={!certificationLoading ? close : undefined}
            disabled={Boolean(certificationLoading)}
          >
            Close
          </Button>
          <Button
            circular
            basic
            secondary
            onClick={this.declineCertification}
            disabled={Boolean(certificationLoading)}
            loading={certificationLoading === 'decline'}
          >
            Decline certification
          </Button>
          <Button
            circular
            primary
            onClick={this.completeCertification}
            disabled={Boolean(certificationLoading)}
            loading={certificationLoading === 'approve'}
          >
            Complete certification
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
