import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import classNames from 'classnames';
import { Progress, Message, Button } from 'semantic-ui-react';
import uniqueId from 'lodash/uniqueId';
import update from 'immutability-helper';
import { uploadFile } from '../services/files';
import { createSelector } from 'reselect';
import { convertBytesToMegaBytes, precisionRound } from '../modules/units';
import Slicon from './icons/Slicon';

const makeGetProgression = function getProgressionSelector() {
  return createSelector(
    state => state.progressions,
    progressions => {
      const nbProgs = progressions.length;
      if (nbProgs === 0) {
        return 0;
      }

      const sumProgs = progressions.reduce(
        (totalProg, prog) => totalProg + prog,
        0,
      );

      return Math.floor(sumProgs / nbProgs);
    },
  );
};

const humaniseAcceptFiles = function humaniseAcceptFiles(acceptStr) {
  const types = acceptStr.split(', ');

  const list = types.map(fullType => {
    if (fullType[0] === '.') {
      return `*${fullType}`;
    }

    const [type, subType] = fullType.split('/');
    if (subType === '*') {
      return `any ${type} file`;
    }

    return `*.${subType}`;
  });

  const [firstLetter, ...string] = list.join(', ');

  // Capitalise first letter
  return `${firstLetter.toUpperCase()}${string.join('')}`;
};

const humaniseBytes = function humaniseBytes(nbBytes) {
  const nbMegabytes = convertBytesToMegaBytes(nbBytes);
  return precisionRound(nbMegabytes, 1);
};

class Dropzone extends Component {
  static propTypes = {
    onFinishUpload: PropTypes.func.isRequired,
    pathToUploadTo: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    onDrop: PropTypes.func,
    multiple: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    disabled: undefined,
    inputProps: {},
    label: 'Drag and drop files here',
    onDrop: undefined,
    multiple: false,
  };

  state = {
    error: undefined,
    nbRejectedFiles: 0,
    uploading: false,
    progressions: [], // Store the different progressions
  };

  id = uniqueId('drop-zone-');

  getProgression = makeGetProgression();

  async uploadFiles(files) {
    const { pathToUploadTo } = this.props;

    let error = null;

    try {
      /**
       * FIXME
       * I wanted to upload simunateously all the files, but it looks like
       * Firestore does not really like it and freezes after finishing uploading
       * the first file. So for now, we are uploading files one by one.
       */
      // Upload simunateously all the files
      // await Promise.all(uploadTasks);

      // Upload file one by one
      for (let i = 0; i < files.length; i += 1) {
        const data = files[i];
        const onProgressUpload = progress =>
          this.handleUpdateProgress(i, progress);

        const path = `${pathToUploadTo}/${data.name}`;
        const file = {
          data,
          path,
        };

        await uploadFile(file, onProgressUpload).then(fullUrl => {
          // When done uploading, send a callback to the parent component
          const { onFinishUpload } = this.props;
          return onFinishUpload({
            path,
            fullUrl,
          });
        });
      }
    } catch (internalError) {
      error = internalError;
    }

    this.setState({ error, uploading: false });
  }

  handleDrop = (acceptedFiles = [], rejectedFiles = []) => {
    if (acceptedFiles.length > 0) {
      const { onDrop } = this.props;
      this.setState(
        {
          uploading: true,
          progressions: acceptedFiles.map(() => 0),
          error: undefined,
        },
        () => this.uploadFiles(acceptedFiles),
      );
      if (onDrop) onDrop(acceptedFiles);
    }

    this.setState({ nbRejectedFiles: rejectedFiles.length });
  };

  handleUpdateProgress = (index, progress) => {
    this.setState(
      update(this.state, {
        progressions: {
          [index]: { $set: progress },
        },
      }),
    );
  };

  render() {
    const {
      className,
      children,
      disabled,
      inputProps,
      label,
      onDrop,
      onFinishUpload,
      pathToUploadTo,
      supportedFiles,
      ...props
    } = this.props;
    const { error, nbRejectedFiles, uploading } = this.state;
    const { accept, maxSize } = props;

    const progress = this.getProgression(this.state);

    return (
      <div className={classNames('drop-zone', className)}>
        <ReactDropzone
          inputProps={{ ...inputProps, id: this.id }}
          className="zone"
          activeClassName="-active"
          acceptClassName="-acceptingfile"
          rejectClassName="-rejectingfile"
          disabled={uploading || disabled}
          onDrop={this.handleDrop}
          {...props}
        >
          {!uploading && (
            <Fragment>
              <strong className="smalltitles">{label}</strong>

              <div className="ortext">or</div>

              <Button
                className="-spaced"
                icon
                labelPosition="left"
                primary
                circular
              >
                Add files
                <Slicon name="plus" className="icon" />
              </Button>
            </Fragment>
          )}

          {(uploading || error === null) && (
            <Progress active={uploading} color="blue" percent={progress}>
              {uploading ? 'Uploading...' : 'Done!'}
            </Progress>
          )}

          {nbRejectedFiles > 0 && (
            <p className="short-message -error">
              <strong className="strong">
                {nbRejectedFiles} file{nbRejectedFiles > 1 ? 's' : ''}
              </strong>{' '}
              {nbRejectedFiles > 1 ? 'were' : 'was'} refused.
            </p>
          )}

          {(maxSize || accept) && (
            <div className="supportedfiles">
              <strong className="smalltitles">Accepted files:</strong>
              {maxSize && (
                <p className="text">Max size: {humaniseBytes(maxSize)}MB</p>
              )}
              {accept && <p className="text">{humaniseAcceptFiles(accept)}</p>}
            </div>
          )}

          {children}
        </ReactDropzone>
        {error && <Message error>{error.reason || error.message}</Message>}
      </div>
    );
  }
}

export default Dropzone;
