import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Message, Header, Menu } from 'semantic-ui-react';
import update from 'immutability-helper';
import { createSelector } from 'reselect';

import UserDetails from '../components/users/UserDetails';
import { getUser } from '../services/users';
import { subscribeToUserPosts } from '../services/posts';

import UserHeader from '../components/users/UserHeader';
import HeaderBar from '../components/navigation/HeaderBar';
import PostGrid from '../components/posts/PostGrid';
import { routes } from '../modules/routes';

// import TrainerQualifications from '../components/users/TrainerQualifications';

const nbPostsByFetch = 18;

const getPosts = createSelector(
  [state => state.data.posts, state => state.postData.ignoredPosts],
  (postsMap, ignoredUids) => {
    return [...postsMap.values()].filter(post => !ignoredUids.has(post.uid));
  },
);

const getPostsOrderedByDate = createSelector([getPosts], posts => {
  return [...posts].sort((a, b) => b.dateCreated - a.dateCreated);
});

class UserDetailsPage extends Component {
  state = {
    data: {
      posts: new Map(),
    },
    userData: {
      isLoading: true, // Data is loading when the Component is mounted
      data: {},
      error: null,
    },
    postData: {
      isLastBatch: false,
      isLoading: true,
      /**
       * posts#subscribeToUserPosts subscribes to one extra post. The reason behind that
       * is to check if there's more than post left than the number we fetching
       * to check if it is the last batch or not without sending another request.
       * Basically, this allows to hide the “Load more” button if our last request
       * contained the last posts.
       *
       * To display a consistent number of posts, any extra fetched posts is referenced
       * in this ignored list so that they are not display until the user requests
       * for more posts.
       */
      ignoredPosts: new Set(),
      error: null,
      snapshotLast: undefined,
    },
  };

  /**
   * List of the unsuscribe functions of post subscriptions.
   * Basically, each batch of #nbPostsByFetch (currently 18) is managed by one
   * subscritpion.
   */
  subscriptions = [];

  componentDidMount() {
    const { match: { params: { userId } } } = this.props;

    if (!userId) {
      this.setState(
        update(this.state, {
          userData: {
            error: { $set: 'Error getting the user id' },
            isLoading: { $set: false },
          },
        }),
      );
      return;
    }

    this.refreshUser();
    // this.getNextPosts();
    this.subscribeNextPosts();
  }

  componentWillUnmount() {
    // Unsuscribe from all posts
    this.subscriptions.forEach(unsuscribe => unsuscribe());
  }

  refreshUser() {
    const { match: { params: { userId } } } = this.props;
    const { userData: { isLoading } } = this.state;

    if (!isLoading) {
      this.setState(
        update(this.state, { userData: { isLoading: { $set: true } } }),
      );
    }

    return getUser(userId)
      .then(data => {
        const userData = {
          isLoading: { $set: false },
        };

        if (data) {
          userData.data = { $set: data };
        } else {
          userData.error = { $set: 'Could not find user' };
        }

        this.setState(update(this.state, { userData }));
      })
      .catch(error => {
        console.error(error);
        this.setState(
          update(this.state, {
            userData: {
              error: { $set: error },
              isLoading: { $set: false },
            },
          }),
        );
      });
  }

  /**
   * Creates a new subscription to posts created before #state.postData.startAfter.
   */
  subscribeNextPosts() {
    const { match: { params: { userId } } } = this.props;
    const { postData: { snapshotLast: startAfter, isLoading } } = this.state;

    // Generate a listener with an index
    const useData = this.handleReceivePosts(this.subscriptions.length);

    /**
     * Here, empty the list of ignored posts to display previously fetched posts that
     * were not displayed. Why would we fetch posts we already have?
     */
    const query = {
      postData: {
        ignoredPosts: { $set: new Set() },
        ...(!isLoading ? { isLoading: { $set: true } } : {}),
      },
    };

    this.setState(update(this.state, query), () => {
      /**
       * Since we have an extra post, we can fetch one post less.
       */
      const limit =
        this.subscriptions.length === 0 ? nbPostsByFetch : nbPostsByFetch - 1;

      const newSubscription = subscribeToUserPosts({
        startAfter,
        useData,
        limit,
        userUid: userId,
      });

      // Keep `unsuscribe` function for later use
      this.subscriptions.push(newSubscription);
    });
  }

  /**
   * Gets a new receive post handler for a subscrition. The index is a way of checking
   * which subscription is the last. This insures that the #state.postData.isLastBatch
   * and #state.postData.startAfter are always representative of the last subscription.
   */
  handleReceivePosts = index => ({
    posts: { added, modified, removed, isLastBatch, snapshotLast },
  }) => {
    const isLastPostSub = index === this.subscriptions.length - 1;

    /**
     * The folowing functions return arrays in this structure [uid, data]. The
     * reason behind it is that we have a Map in the state, and `immutability-helper`
     * uses this format for the $add operator.
     */

    const postsToAdd = added.map(post => [post.uid, post]);

    // Ignore last post because we fetched an extra one at the beginning
    const maxPostsByFetch = index === 0 ? nbPostsByFetch : nbPostsByFetch - 1;
    const postToIgnore = postsToAdd
      .slice(maxPostsByFetch)
      .map(([uid, post]) => uid);

    const postsToUpdate = modified.map(post => [post.uid, post]);
    const liftsToRemove = removed.map(({ uid }) => uid);

    // If it is the latest subquery, set the `isLastBatch` and snapshot of the last document
    const latestSubQuery = !isLastPostSub
      ? {}
      : {
          isLastBatch: { $set: isLastBatch },
          snapshotLast: { $set: snapshotLast },
        };

    const query = {
      data: {
        posts: {
          $add: postsToAdd.concat(postsToUpdate),
          $remove: liftsToRemove,
        },
      },
      postData: {
        ignoredPosts: { $add: postToIgnore },
        isLoading: { $set: false },
        ...latestSubQuery,
      },
    };

    this.setState(update(this.state, query));
  };

  handleRequestMorePosts = () => this.subscribeNextPosts();

  requestRefreshUser = () => {
    this.refreshUser();
  };

  renderHeaderBar() {
    return (
      <HeaderBar key="header-bar" as="header">
        <Menu.Item>
          <Link to={routes.users.root} className="ui button primary circular">
            Back to all
          </Link>
        </Menu.Item>
      </HeaderBar>
    );
  }

  renderMessage(error, body = '') {
    const title = typeof error === 'string' ? error : error.toString();

    return (
      <Message key="message" negative>
        <Message.Header>{title}</Message.Header>
        {body && <p>{body}</p>}
      </Message>
    );
  }

  render() {
    const {
      userData: { isLoading, error, data: user },
      postData: { isLastBatch, isLoading: postsAreLoading, error: postError },
    } = this.state;

    if (!user.uid && error) {
      return (
        <Fragment>
          {this.renderHeaderBar()}
          <Container
            as="main"
            key="main-content"
            fluid
            className="main header-body-page"
          >
            {this.renderMessage(
              error,
              'Maybe you could try going back to the list of users?',
            )}
          </Container>
        </Fragment>
      );
    }

    const { bio, dateOfBirth, sport, height, sex, weight, team } = user;

    const details = {
      bio,
      dateOfBirth,
      sport,
      sex,
      team,
      height,
      weight,
    };

    const posts = getPostsOrderedByDate(this.state);

    return (
      <Fragment>
        {this.renderHeaderBar()}
        <Container
          as="main"
          key="main-content"
          fluid
          className="main header-body-page"
        >
          {error && this.renderMessage(error)}
          <UserHeader
            isLoading={isLoading}
            requestRefreshUser={this.requestRefreshUser}
            user={user}
          />
          <div className="body">
            <Header as="h3" size="large">
              Profile
            </Header>
            <section className="section">
              <UserDetails details={details} readOnly loading={isLoading} />
            </section>
            {/* TODO: available for v2 { userType === 'Trainer' ? <TrainerQualifications /> : '' } */}

            <section className="section">
              <Header as="h4">Posts</Header>
              <PostGrid
                posts={posts}
                isLoading={postsAreLoading}
                error={postError}
              />
              {!isLastBatch && (
                <Button
                  secondary
                  circular
                  loading={postsAreLoading}
                  disabled={postsAreLoading}
                  onClick={this.handleRequestMorePosts}
                >
                  Load more
                </Button>
              )}
            </section>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default UserDetailsPage;
