import React, { Component } from 'react';
import PropTypes, { arrayOf, shape, string } from 'prop-types';

import { Header, Input, Form, Button, Label } from 'semantic-ui-react';
import Dropzone from '../Dropzone';
import { storagePaths } from '../../services/files';
import { injectParamsIntoRoute } from '../../modules/routes';

const propTypes = {
  activity: PropTypes.object.isRequired,
  addCriteria: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onFinishUploadVideo: PropTypes.func.isRequired,
  removeCriteria: PropTypes.func.isRequired,
  updateActivity: PropTypes.func.isRequired,
  form: shape({
    criteria: string,
    criteriaList: arrayOf(string).isRequired,
    description: string,
    mediaUrl: string,
  }).isRequired,
};

const defaultProps = {};

class ActivityDetails extends Component {
  handleKeyUpCriteriaInput = ({ keyCode }) => {
    const { addCriteria, form: { criteria } } = this.props;

    // On press enter
    if (keyCode === 13 && criteria) {
      addCriteria();
    }
  };

  renderCriteria() {
    const { form: { criteriaList }, removeCriteria } = this.props;
    return criteriaList.map((criteria, index) => (
      <Label key={index} content={criteria} onRemove={removeCriteria} />
    ));
  }

  render() {
    const {
      activity,
      addCriteria,
      form,
      onChangeInput,
      onFinishUploadVideo,
      loading,
      updateActivity,
    } = this.props;

    const activitiesPath = injectParamsIntoRoute(storagePaths.activities, {
      activityUid: activity.uid,
    });

    return (
      <Form loading={loading}>
        <Header as="h3">
          {activity.name}
          <Button
            type="button"
            circular
            primary
            onClick={updateActivity}
            loading={loading}
            disabled={loading}
          >
            Update
          </Button>
        </Header>

        <Form.Field>
          <label htmlFor="activity-details-form-description">Description</label>
          <Form.TextArea
            name="description"
            id="activity-details-form-description"
            cols="30"
            rows="10"
            value={form.description || activity.description}
            onChange={onChangeInput}
          />
        </Form.Field>

        <Form.Field onSubmit={this.addCriteria}>
          <label htmlFor="activity-details-form-criteria">Criteria</label>
          <Input
            id="activity-details-form-criteria"
            action={{
              'aria-label': 'Add criteria',
              icon: 'add',
              disabled: !form.criteria,
              onClick: addCriteria,
              primary: true,
              type: 'button',
            }}
            onKeyUp={this.handleKeyUpCriteriaInput}
            name="criteria"
            onChange={onChangeInput}
            value={form.criteria}
          />
        </Form.Field>

        <Label.Group circular>{this.renderCriteria()}</Label.Group>

        <Header size="small">Lift video</Header>

        <Dropzone
          accept="video/mp4"
          onFinishUpload={onFinishUploadVideo}
          pathToUploadTo={activitiesPath}
        />

        {activity.mediaType === 'V' && (
          <video
            className="video-container"
            src={form.mediaUrl || activity.mediaUrl}
            controls
          />
        )}
      </Form>
    );
  }
}

ActivityDetails.propTypes = propTypes;
ActivityDetails.defaultProps = defaultProps;

export default ActivityDetails;
