import { storage } from '../modules/firebase';

export const storagePaths = {
  activities: 'public/activities/:activityUid',
};

export const getFileData = ref => {
  return storage.child(ref).getMetadata();
};

/**
 * Removes the specified file from the server.
 * @param { Object } ref - the reference of the document to remove
 * @return { Promise }
 */
export const removeFile = async ref => {
  const fileRef = await storage.child(ref);

  return fileRef.delete().catch(error => {
    console.error(error, 'documents.js > removeFile');
    throw error;
  });
};

/**
 * Listener for the progression of an upload.
 * @callback onUploadProgress
 * @param { number } progress The progress in percentage.
 */

/**
 * Uploads a file to the Cloud Firestore.
 * @param { Object } file The information about the file to upload.
 * @param { File } file.data The data of the file to upload.
 * @param { string } file.path The path to upload the file to. Must end with
 * the file name, e.g. `foo/bar.png`).
 * @param { onUploadProgress } [onUploadProgress] Callback for any progress update.
 * @returns { Promise } A Promise that resolves with the download url (string) when the file
 * finishes uploading.
 */
export const uploadFile = function uploadFile(file, onUploadProgress) {
  const { data, path } = file;

  return new Promise((resolve, reject) => {
    const uploadTask = storage.child(path).put(data);

    uploadTask.on(
      'state_changed',
      snapshot => {
        // Track progress for the UI
        if (onUploadProgress) {
          const { bytesTransferred, totalBytes } = snapshot;
          const progress = bytesTransferred / totalBytes * 100;
          onUploadProgress(progress);
        }
      },
      // Catch any error and pull them up
      internalError => {
        const { reason, message } = internalError;
        const error = new Error(`Internal error: ${reason || message}`);
        error.internalError = internalError;

        console.log(error);
        reject(error);
      },
      // On successful upload, get the url
      () => {
        resolve(uploadTask.snapshot.downloadURL);
      },
    );
  });
};
