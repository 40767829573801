import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import './styles/App.scss';

// API/Middleware/Services
import { routes } from './modules/routes';

import SidebarLayout from './components/navigation/SidebarLayout';

// Pages
import OverviewPage from './pages/OverviewPage';
import UsersPage from './pages/UsersPage';
import UserDetailsPage from './pages/UserDetailsPage';
import NotificationPage from './pages/NotificationPage';
import StandardPage from './pages/StandardPage';

class App extends Component {
  render() {
    return (
      <SidebarLayout>
        <Switch>
          <Route exact path="/overview" component={OverviewPage} />
          <Route exact path={routes.users.root} component={UsersPage} />
          <Route path={routes.users.details} component={UserDetailsPage} />
          <Route path={routes.notifications} component={NotificationPage} />
          <Route path="/standard" component={StandardPage} />
        </Switch>
      </SidebarLayout>
    );
  }
}

export default App;
