import React, { Component } from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';

class ClearableDropdown extends Component {
  handleClear = evt => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(evt, { ...this.props, value: '' });
    }
  };

  render() {
    const { ...dropdownProps } = this.props;
    const { value } = dropdownProps;

    return (
      <div className="clearable-dropdown">
        {value && (
          <Icon
            className="clearbutton"
            link
            name="close"
            onClick={this.handleClear}
          />
        )}
        <Dropdown {...dropdownProps} />
      </div>
    );
  }
}

export default ClearableDropdown;
