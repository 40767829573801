import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Loader } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import update from 'immutability-helper';

import UsersTable from '../components/users/UsersTable';

import { getPendingVerificationUsers } from '../services/users';
import FilterInput from '../components/FilterInput';

const getUsers = createSelector([state => state.users], usersMaps => {
  return [...usersMaps.values()];
});

const getFilteredUsers = createSelector(
  [state => state.users, state => state.filteredUsers],
  (usersMap, filteredIdsSet) => {
    return [...filteredIdsSet].map(uid => usersMap.get(uid));
  },
);

class PendingProfileVerificationPage extends Component {
  static propTypes = {
    mountNode: PropTypes.object.isRequired,
    onNotificationNumberChange: PropTypes.func,
  };

  state = {
    users: new Map(),
    isLoading: true,
    error: undefined,
    filteredUsers: new Set(),
  };

  componentDidMount() {
    getPendingVerificationUsers().then(users => {
      const { onNotificationNumberChange } = this.props;
      const { filteredUsers } = this.state;

      // Prepare users to be inserted in a Map<Uid, User>
      const uidUsers = users.map(user => [user.uid, user]);
      const usersMap = new Map(uidUsers);

      // Remove uids from the filter list that don't exist anymore
      const removedUserIds = [...filteredUsers].filter(uid =>
        usersMap.has(uid),
      );

      // Notify parent container of the new number of notification
      if (onNotificationNumberChange) {
        const nbNotifProfile = usersMap.size;
        onNotificationNumberChange('profilesToVerify', nbNotifProfile);
      }

      this.setState(
        update(this.state, {
          users: { $set: usersMap },
          error: { $set: null },
          isLoading: { $set: false },
          filteredUsers: { $remove: removedUserIds },
        }),
      );
    });
  }

  /**
   * Handles the newly filtered uids from the `FilterInput`.
   */
  handleFilteredData = uids => {
    this.setState({ filteredUsers: new Set(uids) });
  };

  renderPortalSearchBar() {
    const { mountNode } = this.props;
    const users = getUsers(this.state);

    return ReactDOM.createPortal(
      <FilterInput
        data={users}
        fields={['username', 'firstName', 'lastName']}
        onFilter={this.handleFilteredData}
        placeholder="Search Users (username, first name, last name)"
      />,
      mountNode,
    );
  }

  render() {
    const { isLoading } = this.state;
    const filteredUsers = getFilteredUsers(this.state);

    return (
      <Fragment>
        {this.renderPortalSearchBar()}
        <Loader active={isLoading} inline="centered" size="huge" />
        {!isLoading && <UsersTable users={filteredUsers} />}
      </Fragment>
    );
  }
}

export default PendingProfileVerificationPage;
