import React, { Component } from 'react';
import { shape, string, bool, object, func, number } from 'prop-types';

const initialState = {
  /**
   * Represents whether the application is currently fetching the currently logged in
   * user's data.
   */
  isFetching: false,

  /**
   * The currently logged-in user's profile.
   */
  profile: undefined,

  error: undefined,

  lastUpdatedAt: undefined,
};

const initialContext = {
  ...initialState,
  refreshCurrentUser: () =>
    console.warn(
      'Warning: refreshCurrentUser() called before context has been set',
    ),
};

const { Consumer, Provider } = React.createContext(initialContext);

const propType = shape({
  profile: shape({
    uid: string.isRequired,
    username: string,
    photoUrl: string,
  }),
  isFetching: bool.isRequired,
  refreshCurrentUser: func.isRequired,
  error: object,
  lastUpdatedAt: number,
});

export const LoggedInUserContext = {
  initialState,
  initialContext,
  Consumer,
  Provider,
  propType,
};

/**
 * Higher-Order Component to connect the currently logged-in user state to the
 * specified component.
 *
 * @param { Component } WrappedComponent The component to connect with user data.
 * @returns { Component } The wrapped component with a `currentUserState` prop.
 */
const withLoggedInUser = function withLoggedInUser(WrappedComponent) {
  const wrappedDisplayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return class WithLoggedInUser extends Component {
    static displayName = `withLoggedInUser(${wrappedDisplayName})`;

    render() {
      const { ...wrappedProps } = this.props;

      return (
        <LoggedInUserContext.Consumer>
          {currentUserState => (
            <WrappedComponent
              currentUserState={currentUserState}
              {...wrappedProps}
            />
          )}
        </LoggedInUserContext.Consumer>
      );
    }
  };
};

export default withLoggedInUser;
