import React, { Component, Fragment } from 'react';
import { Container, Loader, Menu } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import _ from 'lodash';

import HeaderBar from '../components/navigation/HeaderBar';
import Users from '../components/users/Users';

import { getUsers } from '../services/users';
import FilterInput from '../components/FilterInput';
import update from 'immutability-helper';
import { matchFilters } from '../modules/filtering';

/*
 * State data selectors
 */

/**
 * Gets all the users in the state.
 */
const getAllUsers = createSelector([state => state.users], usersMap => {
  return [...usersMap.values()];
});

/**
 * Gets the users that match the text inputted in the search bar.
 */
const getSearchedUsers = createSelector(
  [state => state.users, state => state.searchedUsers],
  (usersMap, usersUids) => {
    return [...usersUids].map(uid => usersMap.get(uid));
  },
);

/**
 * Gets the users that match the text inputted in the search bar AND the filters.
 */
const getFilteredUsers = createSelector(
  [getSearchedUsers, state => state.filters],
  (users, filters) => {
    return users.filter(user => matchFilters(user, filters));
  },
);

/**
 * Gets a sorted generated list of sport options based on users.
 */
const getAllSportOptions = createSelector([getAllUsers], users => {
  const userSports = users
    .filter(user => user.sport) // Filter users without a sport value
    .map(({ sport }) => [sport.toLowerCase(), sport]); // Convert to lowercase for consistency

  // Use a map with the lower case sport to remove duplicate values
  const optionsMap = new Map(userSports);

  const actualOptions = [...optionsMap.values()].map(value => ({
    value,
    key: value.toLowerCase(),
    text: value,
  }));

  return _.sortBy(actualOptions, ['key']);
});

/**
 * Gets a sorted generated list of sport level options based on users.
 */
const getAllSportLevelOptions = createSelector([getAllUsers], users => {
  const userSportLevels = users
    .filter(user => user.sportLevel) // Filter users without a sport level
    .map(({ sportLevel }) => [sportLevel.toLowerCase(), sportLevel]); // Convert to lowercase for consistency

  // Use a map with the lower case sport to remove duplicate values
  const optionsMap = new Map(userSportLevels);

  const actualOptions = [...optionsMap.values()].map(value => ({
    value,
    key: value.toLowerCase(),
    text: value,
  }));

  return _.sortBy(actualOptions, ['key']);
});

class UsersPage extends Component {
  state = {
    isLoading: true,
    users: new Map(),
    searchedUsers: new Set(),
    filters: {
      sport: '',
      sportLevel: '',
      userType: new Set(), // Allows to filter on multiple user types
      verified: '',
    },
  };

  componentDidMount() {
    this.handleRefreshData();
  }

  handleRefreshData = () =>
    getUsers().then(users => {
      const { searchedUsers } = this.state;

      // Prepare users to be inserted in a Map<Uid, User>
      const uidUsers = users.map(user => [user.uid, user]);
      const usersMap = new Map(uidUsers);

      // Remove uids from the filter list that don't exist anymore
      const removedUserIds = [...searchedUsers].filter(uid =>
        usersMap.has(uid),
      );

      this.setState(
        update(this.state, {
          users: { $set: usersMap },
          error: { $set: null },
          isLoading: { $set: false },
          searchedUsers: { $remove: removedUserIds },
        }),
      );
    });

  /**
   * Handles the newly searched uids from the `FilterInput`.
   */
  handleSearchedData = uids => {
    this.setState({ searchedUsers: new Set(uids) });
  };

  handleUpdateFilter = (evt, { name, value }) => {
    const { filters: { [name]: currentValue } } = this.state;

    let action = { $set: value };
    if (currentValue instanceof Set) {
      // In a set, add or remove the value
      action = { [currentValue.has(value) ? '$remove' : '$add']: [value] };
    }

    this.setState(
      update(this.state, {
        filters: {
          [name]: action,
        },
      }),
    );
  };

  render() {
    const { isLoading, filters } = this.state;

    const users = getAllUsers(this.state);
    const filteredUsers = getFilteredUsers(this.state);

    return (
      <Fragment>
        <HeaderBar as="header">
          <Menu.Item className="-fluid -centre">
            {/* Search for users: username, first/last name */}
            <FilterInput
              data={users}
              fields={['username', 'firstName', 'lastName']}
              onFilter={this.handleSearchedData}
              placeholder="Search Users (username, first name, last name)"
            />
          </Menu.Item>
        </HeaderBar>

        {/* Page content: Users Table */}
        <Container as="main" className="main" fluid>
          <Loader active={isLoading} inline="centered" size="huge" />
          {!isLoading && (
            <Users
              filters={filters}
              users={filteredUsers}
              onChangeFilter={this.handleUpdateFilter}
              sportOptions={getAllSportOptions(this.state)}
              lvlOptions={getAllSportLevelOptions(this.state)}
              requestRefreshData={this.handleRefreshData}
            />
          )}
        </Container>
      </Fragment>
    );
  }
}

export default UsersPage;
