import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'semantic-ui-react';

const LoginForm = ({ handleSubmit, ...otherProps }) => (
  <Form onSubmit={handleSubmit} {...otherProps}>
    <Form.Field>
      <label htmlFor="email">Email Address</label>
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Email Address"
        autoFocus
        required
      />
    </Form.Field>
    <Form.Field>
      <label htmlFor="password">Password</label>
      <input
        type="password"
        id="password"
        name="password"
        placeholder="Password"
        required
      />
    </Form.Field>
    <Button type="submit" circular fluid primary>
      Sign In
    </Button>
  </Form>
);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
